import { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged, signOut, RecaptchaVerifier, signInWithPhoneNumber, } from "firebase/auth";
import { auth } from "../firebase";
//import axios from "axios";
const userAuthContext = createContext();
export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  function logOut() {
    localStorage.removeItem("user");
    return signOut(auth);
  }



  function setUpRecaptha(number) {
      const recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container', 
        {
      'size': 'invisible',
      
      },auth
      );
      recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);

  
    /*  const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);*/
 
  }






  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      //console.log("Auth", currentuser);
      //console.log("Auth", currentuser.accessToken);
      if(currentuser){
        console.log(currentuser.phoneNumber);
      }
      setUser(currentuser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user, logOut, setUpRecaptha,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}
export function useUserAuth() {
  return useContext(userAuthContext);
}
