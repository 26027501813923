// import React, { useState } from "react";
import React, { useState, useEffect } from 'react';
import InnerBanner from "./InnerBanner";
import axios from "axios";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Styles/DashboardSlider.css";
import {baseURL } from "../services/Aut.post.js";









function Dashboard() {
  const [contestData, setContestData] = useState([]);

  // const [currentDateTime, setCurrentDateTime] = useState('');
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
   
  //   const fetchDateTime = async () => {
  //     try {
  //       const response = await fetch('http://worldtimeapi.org/api/timezone/Asia/Kolkata'); // Replace with your desired location
  //       const data = await response.json();
  //       setCurrentDateTime(data.datetime);
  //       setLoading(false);

  //       // Only show the alert after the time has been fetched
  //       alert(new Date(data.datetime).toLocaleString());
  //     } catch (error) {
  //       alert(2345)
  //       console.error('Error fetching time:', error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchDateTime();
  // }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
         
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };


  const whyBestCard = contestData.map((aboutObj)=>{
   
    // return(
    //     <div className='border-1' key={1}>
    //      <a href={`/GameStepOne?contest=${aboutObj['contestID']}`}><img src={`${baseURL}${aboutObj['Contestlogo']}`} alt="contest"/></a>
    //         <h3>{aboutObj['ContestName']}</h3>
    //         <p>Tockens : <s>{aboutObj['Cost']}</s> {aboutObj['Discounted_price']}</p>
           
    //     </div>
    // )
    return (
      <>
          
              <div className='border-1' key={1}>
                  <a href={`/GameStepOne?contest=${aboutObj['contestID']}`}>
                      <img src={`${baseURL}${aboutObj['Contestlogo']}`} alt="contest"/>
                  </a>
                  <h3>{aboutObj['ContestName']}</h3>
          {/* <h3>{aboutObj['getNoOFwinners']}</h3>
          <h3>{aboutObj['finalWinnerCount']}</h3>
          <h3>{aboutObj['contestOverForWin']}</h3> */}
                  {/* <p>{new Date(currentDateTime).toLocaleString()}</p> */}
                  <p>Tockens : <s>{aboutObj['Cost']}</s> {aboutObj['Discounted_price']}</p>
              </div>
              
         
      </>
  );
  })


 


  useEffect(() => {
   
    const fetchData = async () => {
      try {
        const response = await axios.post(`${baseURL}/userapi/getcontests/`, null, { headers });
        setContestData(response.data); // Update the state with fetched data
      } catch (error) {
        console.error('Error fetching contests:', error);
        // Handle error gracefully
      }
    };
  
    fetchData(); // Call the fetchData function
  }, []); // Include accessToken in the dependency array
  

 
// const fetchGameSettings = async () => {
//     try {
//         const response = await axios.get(`${baseURL}/userapi/getcontestssettings/`);
//         console.log(response.data)
    
//         localStorage.setItem('gridcount', response.data.grid);
//     } catch (error) {
//         console.error('Error fetching game settings:', error);
//     }
// };
// useEffect(() => {
//   fetchGameSettings(); // Fetch dynamic settings when the component mounts
// }, []);
 

  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper">
          <h1>Contests </h1>
          <div className="DashboardSlider">
          <Slider {...settings}>
          {whyBestCard}
          </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
