import React, { useState, useEffect, useRef } from "react";
import "./Styles/PuzzleGame.css";
import InnerBanner from "./InnerBanner";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../services/Aut.post.js";
import axios from "axios";
const PuzzlePiece = ({ number, onClick }) => (
  <div className="puzzle-piece" onClick={onClick}>
    {number !== null ? number : ""}
  </div>
);

const GameChance = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [size, setSize] = useState(4);
  const [maxClicks, setMaxClicks] = useState();
  const [timeLimit, setTimeLimit] = useState();
  const [grid, setGrid] = useState([]);
  const [emptyCell, setEmptyCell] = useState({ row: size - 1, col: size - 1 });
  const [clickCount, setClickCount] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const gameValue = urlParams.get('contest');
  const gamestage = urlParams.get('stage');
  const intervalRef = useRef(null);
  const recaptchaRef = useRef(null);
  const navigation = useNavigate();

  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  const contestId = new URLSearchParams(window.location.search).get("contest");


  



  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "Are you sure you want to exit the game?";
  //     return event.returnValue;
  //   };

  //   const handlePopState = () => {
  //     if (window.confirm("Are you sure you want to exit the game?")) {
  //       window.location.href = "/dashboard";
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, []);

  useEffect(() => {
    initPuzzle(generateRandomNumbers());
    startTimer();
  }, []);

  const formData = {
    // Your form data goes here
    ContestSettings: gameValue,
    stageno:gamestage
  };



  useEffect(() => { 
    axios.post(`${baseURL}/userapi/getcontestssettings/`, formData, { headers: headers })
        .then(response => {
            // Extract only time and counts
            const { no_of_seconds, no_of_moves, no_of_lives } = response.data;
            
            // Set the extracted data
            setMaxClicks(no_of_moves);    // Set count for moves
            setTimeLimit(no_of_seconds);  // Set the time limit

            // Optional: Store the number of lives in localStorage if not already set
            const storedLives = JSON.parse(localStorage.getItem("no_of_lives"));
            if (storedLives === null) {
                localStorage.setItem("no_of_lives", JSON.stringify(no_of_lives));
            }
        })
        .catch(error => {
            console.error('API Error:', error);
        });
}, []); 


//jagsdeesh
//API for tockens tocken_withdraw_and_credit
  useEffect(() => {
    const setTockensData = {
      Tocken:  Number(localStorage.getItem("contestTockesn")),
      credit_for_id:localStorage.getItem("contestid"),
      tocken_type: "contest",
      credit_type: 0      
    };
    
      
    const setDataForTockesnList = async () => {
      try {
        const response = await axios.post(
          `${baseURL}/tockens_api/tocken_withdraw_and_credit/`,
          setTockensData,
          { headers }
        );
        
        console.log(response.data); // Update the state with fetched data
      } catch (error) {
        console.error("Error fetching contests:", error);
        // Handle error gracefully
      }
    };

    setDataForTockesnList();
  
  }, []);



  useEffect(() => {
    if (isSolved() && clickCount > 0) {
      clearInterval(intervalRef.current);
      alert(
        `Congratulations! You solved the puzzle in ${clickCount} clicks and ${timeElapsed} seconds.`
      );
      navigation("/Congratulations");

      // API call to submit contest result
      submitContestResult();

    } else if (timeElapsed >= timeLimit) {
      clearInterval(intervalRef.current);
      alert(`Time's up! You exceeded the time limit of ${timeLimit} seconds.`);
      window.location.href = "/dashboard";
    }
    sessionStorage.setItem("test_Time", timeElapsed);
  }, [clickCount, timeElapsed]);

  const submitContestResult = async () => {
    const resultData = {
      // user_id: "user123", // Replace with dynamic user ID
      date_time: new Date().toISOString(),
      contest_id: contestId, // Replace with dynamic contest ID
      click_count: clickCount,
      time_count: timeElapsed,
      result: "win",
    };
    // const resultData = {
    //     user_id: "user123", // Static user ID
    //     date_time: "2024-08-28T10:00:00Z", // Static date and time
    //     contest_id: "contest789", // Static contest ID
    //     click_count: 150, // Static click count
    //     time_count: 1200, // Static time count
    //     result: "win" // Static result
    // };

    console.log(resultData);

    try {
      const response = await fetch(
        `${baseURL}/tockens_api/submit_contest_result/`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(resultData),
        }
      );

      console.log("RESPONSE", response);

      if (!response.ok) {
        throw new Error("Failed to submit contest result");
      }

      const responseData = await response.json();
      console.log("RESULTT", responseData);
      console.log("Contest result submitted successfully:", responseData);
    } catch (error) {
      console.error("Error submitting contest result:", error);
    }
  };

  const generateRandomNumbers = () => {
    const excludedNumbers = [20, 30, 40, 50, 60, 70, 80, 90];
    const numbers = [];
    const min = 11;
    const max = 99;
    while (numbers.length < size * size - 1) {
      const num = Math.floor(Math.random() * (max - min + 1)) + min;
      if (!excludedNumbers.includes(num) && !numbers.includes(num)) {
        numbers.push(num);
      }
    }
    numbers.push(null);
    return numbers;
  };

  const initPuzzle = (numbers) => {
    numbers.sort(() => Math.random() - 0.5);
    const newGrid = [];
    for (let i = 0; i < size * size; i++) {
      const row = Math.floor(i / size);
      const col = i % size;
      newGrid.push({ number: numbers[i], row, col });
      if (numbers[i] === null) {
        setEmptyCell({ row, col });
      }
    }
    setGrid(newGrid);
  };

  const startTimer = () => {
    setTimerRunning(true);
  };

  useEffect(() => {
    if (timerRunning) {
      intervalRef.current = setInterval(() => {
        setTimeElapsed((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [timerRunning]);

  const isSolved = () => {
    const sortedNumbers = grid
      .filter(({ number }) => number !== null)
      .map(({ number }) => number);

    for (let i = 1; i < sortedNumbers.length; i++) {
      if (sortedNumbers[i] < sortedNumbers[i - 1]) {
        return false;
      }
    }

    return grid.length === 0 || grid[grid.length - 1].number === null;
  };

  const movePiece = (clickedCell) => {
    if (!timerRunning) {
      startTimer();
    }
    const clickedIndex = clickedCell.row * size + clickedCell.col;
    const emptyIndex = emptyCell.row * size + emptyCell.col;
    if (clickCount >= maxClicks) {
      alert("Game Over! You reached the maximum number of clicks.");
      window.location.href = "/dashboard";
    }
    if (
      (Math.abs(clickedCell.row - emptyCell.row) === 1 &&
        clickedCell.col === emptyCell.col) ||
      (Math.abs(clickedCell.col - emptyCell.col) === 1 &&
        clickedCell.row === emptyCell.row)
    ) {
      const updatedGrid = [...grid];
      [updatedGrid[clickedIndex].number, updatedGrid[emptyIndex].number] = [
        updatedGrid[emptyIndex].number,
        updatedGrid[clickedIndex].number,
      ];
      setGrid(updatedGrid);
      setEmptyCell(clickedCell);
      setClickCount(clickCount + 1);
      // Store the click count in sessionStorage
      sessionStorage.setItem("test_Clicks", clickCount + 1);
    }
  };

  const renderGrid = () => {
    return grid.map(({ number, row, col }) => (
      <PuzzlePiece
        key={`${row}-${col}`}
        number={number}
        onClick={() => movePiece({ row, col })}
      />
    ));
  };

  const restartGame = () => {
    initPuzzle(generateRandomNumbers());
    setClickCount(0);
    setTimeElapsed(0);
    startTimer();
    // Clear sessionStorage keys on restart
    sessionStorage.removeItem("test_Clicks");
    sessionStorage.removeItem("test_names");
  };

  return (
    <div>
      <InnerBanner />
      <div className="puzzle-game">
        <div className="game-content-main">
          <div className="gc-1">
            <b>No of Clicks Pending:</b> {Number(maxClicks-clickCount)} ({maxClicks})
          </div>
          <div className="gc-1">
            <b>No of Seconds Pending:</b> {timeElapsed} ({timeLimit})
          </div>
        </div>
        <div
          id="puzzle-container"
          style={{
            gridTemplateColumns: `repeat(${size}, 1fr)`,
            gridTemplateRows: `repeat(${size}, 1fr)`,
          }}
        >
          {renderGrid()}
        </div>
        <div className="info"></div>
        <div>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="YOUR_SITE_KEY"
          />
        </div>
      </div>
    </div>
  );
};

export default GameChance;
