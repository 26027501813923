import React, { useEffect, useState } from "react";
import InnerBanner from "./InnerBanner";
import { Link } from "react-router-dom";
import "./Styles/ChangeAddress.css";
import { Form, Alert } from "react-bootstrap";
import axios from "axios";
import { getPanaandAadhar,baseURL } from "../services/Aut.post.js";

function MyAccountStatus() {
  //using for fetch countries details 


  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };
  const [formData, setFormData] = useState({
    
      aadharcard: "",
      pancard: "",
  
   
  });
  const handleInputChange = (e) => {
   
    const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log(formData)
  
    try {
      // Make an Axios POST request to your API endpoint
      const response = await axios.post(
        `${baseURL}/userapi/useraadharpan/`,
        formData,
        { headers: headers }
      );
      setSuccessMessage("successfully updated");
      // Handle the response as neededx
      // console.log("API Response:", response.data);
    } catch (error) {
      // Handle errors
      setErrorMessage("something went wrong");
      // console.error("Error submitting form:", error);
    }
  };



  




  useEffect(() => {


    const fetchData = async () => {
      try {
        const data = await getPanaandAadhar(btoken);
        console.log(data);
        setFormData({
    
          aadharcard: data.aadhar_card,
          pancard: data.pan_card,
          
        });
        

      } catch (error) {
        // Handle error if needed
      }
    };
    
  
    fetchData();
  }, [btoken]);
  




  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 ChangeAddress-wrap">
        <div className="wrapper">
          <h1>Aadhar-Pan Details </h1>

          <div>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
          </div>
          <div>
            <Form onSubmit={handleSubmit}>
              <div className="ChangeAddress-form-wrap">
               
                
                <div className="reg-1">
                  <b>Pan</b>
                  {/* <input type='text' className='text-f-1' /> */}
                  <input
                  required
                    type="text"
                    name="pancard"
                    value={formData.pancard}
                    onChange={handleInputChange}
                    className="text-f-1"
                  />
                </div>
                <div className="reg-1">
                  <b>Aadhar</b>
                  {/* <input type='text' className='text-f-1' /> */}
                  <input
                  required
                    type="text"
                    name="aadharcard"
                    value={formData.aadharcard}
                    onChange={handleInputChange}
                    className="text-f-1"
                  />
                </div>
               
                <div className="reg-1 w-100 text-center">
                  <button className="btn-2">Save</button> &nbsp;&nbsp;
                  <Link to="/MyAccountStatus">
                    {" "}
                    <button className="btn-1">Cancel</button>
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MyAccountStatus;
