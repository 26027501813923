import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
const App = () => {
  useEffect(() => {
    const token = Cookies.get('access_token');
    alert(token)

    const user = {
      signup_status: true,
      refresh: "sfsdfsdfdfdsfREFD",
      access: "mytockensddssd",
    };

// Stringify the user object before storing it
localStorage.setItem('user', JSON.stringify(user));
  }, []);

  return (
    <div>
      <h1>Tockens Application</h1>
    </div>
  );
};

export default App;
