import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Styles/SignUp.css";
import { Form } from "react-bootstrap";
import Banner from "./Banner";
import axios from "axios";
import { getReferralcode, baseURL } from "../services/Aut.post.js";

const SignUp = ({ handleLogin }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dynamicRefCode = queryParams.get("ref");
  const userPhoneNumber = JSON.parse(localStorage.getItem("userphonenumber"));

  const navigate = useNavigate();
//!--------useState - storing the data in state -- START--------------------!//
  const [userData, setUserData] = useState(null);
  const [formError, setFormError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [referralCode, setReferralCode] = useState({});
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    date_of_birth: "",
    gender: "",
    country_of_residence: "IN", // Setting default value for country
    city_of_residence: "",
    pincode: "",
    full_address: "",
    refer_user_id: "",
    citizenship: "",
    signup_status: true,
    phone_number: userPhoneNumber,
  });
//!------------END-----------!//

//!------------useEffect for fetching country and referral code data--- START-----------!//
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await axios.post(`${baseURL}/userapi/getcountryview/`);// API call to fetch country data
        setCountries(response.data);// Set the countries in the state
      } catch (error) {
        console.error("Error fetching country data:", error);// Log any errors related to the country data fetching
      }
    };

    const fetchReferralCode = async () => {
      try {
        const storedRefCode = localStorage.getItem("referral_code"); // Retrieve referral code from localStorage
        let code = "";
        if (storedRefCode) {
          code = storedRefCode;
          //setReferralCode(storedRefCode); // Set the referral code if it exists in localStorage
        } else if (dynamicRefCode){ 
          code = dynamicRefCode;
          //setReferralCode(dynamicRefCode); // Set the referral code if present in the URL query parameter
        } else {
          const data = await getReferralcode({ phoneNumber: userPhoneNumber }); // Fetch referral code from API using the phone number
          //setReferralCode(data.referral_code || ""); // Set the referral code from the API response
          code = data.referral_code || "";
        }

        setReferralCode(code); // Set the referral code in the state
      setFormData((prevFormData) => ({
        ...prevFormData, // Preserve the existing form data
        refer_user_id: code, // Update the refer_user_id in formData
      }));
      } catch (error) {
        console.error("Error fetching referral code:", error); // Log any errors related to referral code fetching
      }
    };

    fetchCountryData(); // Trigger the country data fetch on component mount
    fetchReferralCode(); // Trigger the referral code fetch on component mount
  }, [dynamicRefCode, userPhoneNumber]); // Re-run if dynamicRefCode or userPhoneNumber changes
//!------------useEffect - END-------------!//

//!------------Handle input changes in form----START----------!//
  const handleInputChange = (e) => {
    e.preventDefault(); // Prevent default form behavior
    const { name, value } = e.target; // Destructure the name and value of the form input field

    if (name === "refer_user_id") { 
      setReferralCode(value); 
    }

    setFormData((prevFormData) => ({
      ...prevFormData, // Keep the previous form data intact
      [name]: value, // Update the changed form field in the formData state
    }));
  };
// !----------END----------!//

//!------------Handle form submission ----START-----------!//
  const handleSubmit = async (e) => {
    e.preventDefault();// Prevent the default form submission behavior

    const headers = {
      "Content-Type": "application/json",// Set the request headers for the POST request
    };

    // alert(`Referral code: ${referralCode}`); // Corrected alert
    // console.log(formData);
    try {
      const response = await axios.post(
        `${baseURL}/userapi/singupvaluespost/`,// API endpoint for form submission
        formData, // Send the form data as the request body
        {
          headers: headers, // Include the headers in the request
        }
      );

      localStorage.removeItem("userLoginStatus"); // Remove the login status from localStorage upon successful submission
      setUserData(response.data); // Set the user data in the state with the response data
      localStorage.setItem("user", JSON.stringify(response.data)); // Save the user data to localStorage

      const token = JSON.stringify(response.data); // Create a token from the user data
      handleLogin(token); // Call the handleLogin function passed as a prop to log in the user
      navigate("/Dashboard"); // Navigate the user to the dashboard upon successful signup
    } catch (error) {
      if (error.response?.data?.error) {
        setFormError(error.response.data.error);// Display specific error message if available
      } else {
        setFormError(
          "Error submitting form: Please check email, user with this email already exists."
        );// Display a general error message

      }
      console.error(error);// Log any error during the submission
    }
  };
// !--------------END-----------!//

  // Determine if the referral code should be read-only
  const isReferralCodeReadOnly = Boolean(localStorage.getItem("referral_code") || dynamicRefCode);// Set referral code input as read-only if it exists in localStorage



   //!------------Return JSX for form UI-----------!//
  return (
    <div>
      <div className="Signup-Bg">
        <div className="Signup-wrap">
          <Form onSubmit={handleSubmit}>
            <div className="Signup-Header-Text">
              <h2>Signup</h2>
              <h6>
                To complete the signup process, please enter the information
                below.
              </h6>
            </div>
            {formError && <p>{formError}</p>}

            <div className="reg-wrap">
              <div className="reg-1">
                <b>First Name</b>
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  className="text-f-1"
                  required
                />
              </div>
              <div className="reg-1">
                <b>Last Name</b>
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  className="text-f-1"
                  required
                />
              </div>
              <div className="reg-1">
                <b>Email</b>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="text-f-1"
                  required
                />
              </div>
              <div className="reg-1">
                <b>Date of Birth</b>
                <input
                  type="date"
                  name="date_of_birth"
                  value={formData.date_of_birth}
                  onChange={handleInputChange}
                  className="text-f-1"
                  required
                />
              </div>
              <div className="reg-1">
                <b>Gender</b>
                <select
                  id="mySelect"
                  className="text-f-1"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="reg-1">
                <b>Country of Residence</b>
                <select
                  name="country_of_residence"
                  value={formData.country_of_residence} // Updated to use formData
                  onChange={handleInputChange} // handleInputChange handles it
                  className="text-f-1"
                  required
                >
                  {countries.map((country) => (
                    <option
                      key={country.country_code}
                      value={country.country_code}
                    >
                      {country.country_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="reg-1">
                <b>City of Residence</b>
                <input
                  type="text"
                  name="city_of_residence"
                  value={formData.city_of_residence}
                  onChange={handleInputChange}
                  className="text-f-1"
                />
              </div>
              <div className="reg-1">
                <b>Pincode / Zipcode</b>
                <input
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleInputChange}
                  className="text-f-1"
                />
              </div>
              <div className="reg-1 w-100">
                <b>Full Address</b>
                <input
                  type="text"
                  name="full_address"
                  value={formData.full_address}
                  onChange={handleInputChange}
                  className="text-f-1"
                />
              </div>
              <div className="reg-1">
                <b>Referral Code</b>
                <input
                  type="text"
                  name="refer_user_id"
                  // value={formData.refer_user_id}
                  value={referralCode !== "" ? referralCode : formData.refer_user_id}

                  // onChange={(e) => setReferralCode(e.target.value)}
                  onChange={handleInputChange}
                  className="text-f-1"
                  readOnly={isReferralCodeReadOnly}
                />
              </div>
              <div className="reg-1">
                <b>Citizenship</b>
                <input
                  type="text"
                  name="citizenship"
                  value={formData.citizenship}
                  onChange={handleInputChange}
                  className="text-f-1"
                />
              </div>
              <div className="reg-1 w-100 text-center">
                <button className="Signup-button">Signup</button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Banner />
    </div>
  );
};

export default SignUp;
