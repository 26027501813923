import React, { useState } from "react";
import InnerBanner from "./InnerBanner";
import "./Styles/Tc.css";

function AboutUs() {
  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper">
          <h1>About Us</h1>
          <div className="TC-Text">
            <div className="TermsCondtnsBox">
              <h5>About Us</h5>
              <ol>
                <li>
                  <h3>Big Bonanza</h3>
                  <div>
                    <div>
                      XENFORD INTERNET PRIVATE LIMITED is a dynamic gaming company committed to delivering top-tier entertainment to players worldwide. With a passion for gaming and a focus on innovation, we strive to create experiences that captivate and inspire.
                    </div>
					
                  </div>
                  <div>
                    <div>
                      Big Bonanza is the culmination of our dedication to excellence in gaming. Offering a diverse array of games, Big Bonanza provides an immersive and enjoyable gaming experience for players of all levels. Whether you're a casual gamer or a seasoned pro, Big Bonanza offers something for everyone to enjoy.
                    </div>
					
                  </div>
                </li>
               
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
