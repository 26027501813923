import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
//pradeeep
// const firebaseConfig = {
//   apiKey: "AIzaSyCqrF15GZ-pZN6EeQ6v_Qf4Lxmpd4w_us4",
//   authDomain: "xenfoad.firebaseapp.com",
//   projectId: "xenfoad",
//   storageBucket: "xenfoad.appspot.com",
//   messagingSenderId: "207729660783",
//   appId: "1:207729660783:web:6f747272e6261e8d53ce9a"
// };
// jagadeesh 
  // const firebaseConfig = {
  //   apiKey: "AIzaSyDuOK31_O-KDUX7HFM4-q_WPVq0R1-x8c8",
  //   authDomain: "bigbonanza-5739b.firebaseapp.com",
  //   projectId: "bigbonanza-5739b",
  //   storageBucket: "bigbonanza-5739b.appspot.com",
  //   messagingSenderId: "366332957459",
  //   appId: "1:366332957459:web:c00d8a14200d4dcd9880e6",
  //   measurementId: "G-QPCWGLXJHQ"
  // };

//clent
  const firebaseConfig = {
    apiKey: "AIzaSyB4zRwEqyd8xLZgxu_EM_YFFsOmtuFNX04",
    authDomain: "goodluckcharm.firebaseapp.com",
    projectId: "goodluckcharm",
    storageBucket: "goodluckcharm.appspot.com",
    messagingSenderId: "680354278757",
    appId: "1:680354278757:web:4061d77fbe9d0e48b9c58c"
  };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
