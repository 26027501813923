import React, { useState } from "react";
import QRCode from "react-qr-code";

import "./Styles/QRcode.css"; // Import the CSS file
import { baseURL } from "../services/Aut.post.js";
const QRcode = () => {

    return (
        <div className="qr-container">
            <QRCode
                size={256} // Set a larger size for better scanning
                style={{ height: "auto", maxWidth: "100%", width: "auto" }}
                // value={`http://localhost:3000/phonesignup?ref=${refCode}`}
                value={`http://localhost:3000/phonesignup?ref=FREESUNDAY`}
                // value={`http://localhost:3000/phonesignup?ref=FREESUNDAY&next=https://bigbonanza.in/?contest=12345t`}
                viewBox="0 0 256 256"
            />

            
           
        </div>
    );
};

export default QRcode;
