// api.js
import axios from 'axios';

// const baseURL = 'https://admin.bigbonanza.in';

// const ssoURL = 'http://localhost:3000';
const ssoURL = 'https://bigbonanza.in';
const baseURL = '//admin.bigbonanza.in';
//const baseURL = 'http://127.0.0.1:8000'; // Replace with your actual API base URL
// const accessToken = JSON.parse(localStorage.getItem("user"));
// const btoken=accessToken.access;


const getUserAccountStatus = async (accessToken) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(`${baseURL}/userapi/getmyaccountstatus/`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching user account status:', error);
    throw error;
  }
};

// const getSomeOtherData = async (accessToken) => {
//   // Define headers or any other configurations as needed for the second API call
//   const headers = {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${accessToken}`,
//   };

//   try {
//     const response = await axios.get(`${baseURL}/path/to/other/endpoint`, { headers });
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching other data:', error);
//     throw error;
//   }
// };

// You can add more functions for additional API calls if needed

//Home.js file
//Get User Details form Database 


//get REF code 
const getUserRefCode = async (accessToken) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(`${baseURL}/userapi/getrefcodeValue/`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching user account status:', error);
    throw error;
  }
};


//get Banner images

const getBannerImages = async () => {
  const headers = {
    'Content-Type': 'application/json',
    //'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(`${baseURL}/userapi/getbannerimages/`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching user account status:', error);
    throw error;
  }
};

// Get values for Home banner animation properties
const getBannerAnimationValues = async () => {
  const headers = {
    'Content-Type': 'application/json',
    //'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(`${baseURL}/userapi/banneranimationpropertys/`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching user account status:', error);
    throw error;
  }
};

// Get values for Home banner animation properties
const getReferralcode = async (formDataRef) => {
  const headers = {
    'Content-Type': 'application/json',
    //'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.post(`${baseURL}/userapi/getreferralcode/`,formDataRef, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching user account status:', error);
    throw error;
  }
};

//get address and No of Referrals
const getHomeDetails = async (accessToken) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(`${baseURL}/userapi/gethomedetails/`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching user account status:', error);
    throw error;
  }
};


// Get contest all
const getContest = async (accessToken) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.post(`${baseURL}/userapi/getcontests/`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching user account status:', error);
    throw error;
  }
};

const getPanaandAadhar = async (accessToken) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(`${baseURL}/userapi/getpanaadhar/`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching user account status:', error);
    throw error;
  }
};


const getAllResultDetails = async (firstArrg, secondArg) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(
      `${baseURL}/tockens_api/${firstArrg}/${secondArg}/`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
}

export { getUserAccountStatus,getUserRefCode,getBannerImages,getBannerAnimationValues,getReferralcode,getHomeDetails,getContest,getPanaandAadhar,baseURL, ssoURL, getAllResultDetails };
