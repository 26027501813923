import React, { useEffect, useState } from "react";
import InnerBanner from "./InnerBanner";
import { Link } from "react-router-dom";
import "./Styles/ChangeAddress.css";
import { Form, Alert } from "react-bootstrap";
import axios from "axios";
import { getUserAccountStatus,baseURL } from "../services/Aut.post.js";

function MyAccountStatus() {
  //using for fetch countries details 
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };
  
  const [formData, setFormData] = useState({
    profile: {
      country_of_residence: "",
      city_of_residence: "",
      pincode: "",
      full_address: "",
      citizenship: "",
    },
  });
  const handleInputChange = (e) => {
   
    const { name, value } = e.target;
    // If the input field is nested under 'profile', update the nested state

    if (name === "profile.country_of_residence") {
      // Reset the entire form state
      setFormData({
        profile: {
          country_of_residence: value,
          city_of_residence: "",
          pincode: "",
          full_address: "",
          citizenship: "",
        },
      });
    }

    else if (name.startsWith("profile.")) {
      const profileField = name.split(".")[1];
      setFormData((prevFormData) => ({
        ...prevFormData,
        profile: {
          ...prevFormData.profile,
          [profileField]: value,
        },
      }));
    } else {
      // Otherwise, update the top-level state
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    //update record
    console.log(formData)
   
    try {
      // Make an Axios POST request to your API endpoint
      const response = await axios.post(
        `${baseURL}/userapi/setsingupvaluesupdate/`,
        formData,
        { headers: headers }
      );
      setSuccessMessage("successfully updated");
      // Handle the response as neededx
      console.log("API Response:", response.data);
    } catch (error) {
      // Handle errors
      setErrorMessage("something went wrong");
      console.error("Error submitting form:", error);
    }
  };

  //Get all Contry data for Country of Residence

  const fetchCountryData = async () => {
    try {
      const response = await axios.post(`${baseURL}/userapi/getcountryview/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching country data:', error);
      throw error; // Propagate the error for the component to handle
    }
  };


  //get fetch data and input it values

  const [userAccountData, setUserAccountData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserAccountStatus(btoken);
        setUserAccountData(data);
        console.log(data)
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchData();
  }, [btoken]);
  
  //update previous value in form
  useEffect(() => {
    // Check if userAccountData is not empty and update form data
    if (userAccountData.length > 0) {
      const userAccount = userAccountData[0]; // Assuming user data has a profile property
      
      setSelectedCountry(userAccount.country_of_residence)

      setFormData({
        profile: {
          country_of_residence: userAccount.country_of_residence,
          city_of_residence: userAccount.city_of_residence,
          pincode: userAccount.pincode,
          full_address: userAccount.full_address,
          citizenship: userAccount.citizenship,
        },
      });
    }



  
  }, [userAccountData]);
//fetch country data and set value for country
  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryData = await fetchCountryData();
        setCountries(countryData);
      
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 ChangeAddress-wrap">
        <div className="wrapper">
          <h1>Change Address </h1>

          <div>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
          </div>
          <div>
            <Form onSubmit={handleSubmit}>
              <div className="ChangeAddress-form-wrap">
                <div className="reg-1">
                  <b>Citizenship</b>
                  {/* <input type='text' className='text-f-1' /> */}
                  <input
                  required
                    type="text"
                    name="profile.citizenship"
                    value={formData.profile.citizenship}
                    onChange={handleInputChange}
                    className="text-f-1"
                    
                  />
                </div>
                <div className="reg-1">
                  <b>Country of Residence</b>
                 
                  <select
                  required
                      name="profile.country_of_residence"
                      value={formData.profile.country_of_residence}
                      onChange={handleInputChange}
                      className="text-f-1"
                      required
                    >
                  {countries.map(country => (
                  <option
                  key={country.country_code} 

                  value={country.country_code}
                  selected={country.country_code === formData.profile.country_of_residence}
                  >
                  {country.country_name}
                  </option>
                  ))}
                  </select>
           
                </div>
                <div className="reg-1">
                  <b>City of Residence</b>
                  {/* <input type='text' className='text-f-1' /> */}
                  <input
                  required
                    type="text"
                    name="profile.city_of_residence"
                    value={formData.profile.city_of_residence}
                    onChange={handleInputChange}
                    className="text-f-1"
                  />
                </div>
                <div className="reg-1">
                  <b>Pincode / Zipcode</b>
                  {/* <input type='text' className='text-f-1' /> */}
                  <input
                  required
                    type="text"
                    name="profile.pincode"
                    value={formData.profile.pincode}
                    onChange={handleInputChange}
                    className="text-f-1"
                  />
                </div>
                <div className="reg-1 w-100">
                  <b>Full Address</b>
                  <input
                  required
                    type="text"
                    name="profile.full_address"
                    value={formData.profile.full_address}
                    onChange={handleInputChange}
                    className="text-f-1"
                  />
                </div>
                <div className="reg-1 w-100 text-center">
                  <button className="btn-2">Save</button> &nbsp;&nbsp;
                  <Link to="/MyAccountStatus">
                    {" "}
                    <button className="btn-1">Cancel</button>
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MyAccountStatus;
