import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate and useParams
import InnerBanner from "./InnerBanner.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/ChangeSubscriptions.css";
import axios from "axios";
import { baseURL } from "../services/Aut.post.js";

function ChangeSubscriptions() {
  const [orderDetails, setOrderDetails] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const { subscription_level, subscription_type } = useParams(); // Get parameters from route

  const handleViewChangeForMonthly = () => {
    navigate(`/getsubscriptionvalues/${subscription_level}/Monthly`); // Update URL to Monthly
  };

  const handleViewChangeForYearly = () => {
    navigate(`/getsubscriptionvalues/${subscription_level}/Annual`); // Update URL to Annual
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${baseURL}/userapi/getsubscriptionvalues/${subscription_level}/${subscription_type}/`;

        const response = await axios.get(url);
        setSubscriptions(response.data ? [response.data] : []);
        console.log("Subscription data:", response.data);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    };

    fetchData();
  }, [subscription_level, subscription_type]); // Added subscription_type to dependency array

  useEffect(() => {
    axios
      .post(`${baseURL}/userapi/paymentsetup/`)
      .then((response) => {
        console.log("Response:", response.data);
        setOrderDetails({
          razorpay_order_id: response.data.context.razorpay_order_id,
          razorpay_merchant_key: response.data.context.razorpay_merchant_key,
          razorpay_amount: response.data.context.razorpay_amount,
          currency: response.data.context.currency,
          callback_url: response.data.context.callback_url,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handlePayment = async () => {
    const options = {
      key: orderDetails.razorpay_merchant_key,
      amount: orderDetails.razorpay_amount,
      currency: orderDetails.currency,
      name: "Big Bonanza",
      description: "Test Payment",
      order_id: orderDetails.razorpay_order_id,
      handler: async function (response) {
        alert(response);
        try {
          const responseGet = await axios.post(
            `${baseURL}/userapi/paymenthandler/`,
            response
          );
          console.log("API Response:", responseGet.data);
        } catch (error) {
          console.log("Error:", error.response.data.error);
        }
      },
      prefill: {
        name: "jagadeesh",
        email: "jagadeesh@eparivartan.com",
      },
      notes: {
        address: "rajnagar, hyderabad",
      },
      theme: {
        color: "#F37254",
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  return (
    <div>
      <InnerBanner />
      <div className="Subscriptions-bg-1">
        <div className="wrapper">
          <h1>Subscriptions - {subscription_level}</h1>
          <div className="ChangeSubscriptions-wrap">
            <div className="ChangeSubscriptions-box-1">
            {subscriptions.length > 0 ? (
                    subscriptions.map((subscription) => (
                      <div>
                        <span dangerouslySetInnerHTML={{ __html: subscription.subscription_About }} />
                    <a href="/TermsandConditions">Terms & Conditions</a>
                      </div>
                    ))
                  ) : (
                    <div>No subscriptions available.</div>
                  )}
             
            </div>
            <div className="ChangeSubscriptions-box-2">
              <div>
                <div className="btn-bg-1">
                  <button
                    className={
                      subscription_type === "Monthly" ? "tab-button active" : "tab-button"
                    }
                    onClick={handleViewChangeForMonthly}
                  >
                    Monthly
                  </button>
                  <button
                    className={
                      subscription_type === "Annual" ? "tab-button active" : "tab-button"
                    }
                    onClick={handleViewChangeForYearly}
                  >
                    Yearly
                  </button>
                </div>
                <div className="tab-content">
                  {subscriptions.length > 0 ? (
                    subscriptions.map((subscription) => (
                      <div key={subscription.id}>
                        <h3>
                          <b>₹ {subscription.subscription_Cost}</b> ₹ {subscription.discounted_price}
                        </h3>
                        <button className="btn-2" >
                          Proceed
                        </button>
                      </div>
                    ))
                  ) : (
                    <div>No subscriptions available.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeSubscriptions;
