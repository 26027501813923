import { Link } from "react-router-dom";
import './Styles/Login.css'
import { createPortal } from 'react-dom';
import { useState } from 'react';
import ModalContent from './ModalContent.js';
import BannerImg1 from "./images/banner-img-1.png";
import Banner from "./Banner.js";

const Login = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
       <Banner />
    {showModal && createPortal(
        <ModalContent onClose={() => setShowModal(false)} />,
        document.body
      )}
      
    </>
  );
};
export default Login;
