import React, { useState, useEffect, useRef } from 'react';
import "./Styles/PuzzleGame.css";
import InnerBanner from "./InnerBanner";
import axios from 'axios';
import { baseURL } from "../services/Aut.post.js";
import ReCAPTCHA from 'react-google-recaptcha';

const handleReloadGame = () => {
    window.location.reload();
};

const PuzzlePiece = ({ number, onClick }) => {
    return (
        <div className={`puzzle-piece`} onClick={onClick}>
            {number !== null ? number : ''}
        </div>
    );
};


const PuzzleGame = () => {
    const [nooflives, setNooflives] = useState();
    const [size, setSize] = useState(4);
    const [maxClicks, setMaxClicks] = useState(1000);
    const [timeLimit, setTimeLimit] = useState(3000);
    const [grid, setGrid] = useState([]);
    const [emptyCell, setEmptyCell] = useState({ row: size - 1, col: size - 1 });
    const [clickCount, setClickCount] = useState(0);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [timerRunning, setTimerRunning] = useState(false);
    const intervalRef = useRef(null);
    const recaptchaRef = useRef(null);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
            const confirmationMessage = 'Are you sure you want to exit the game?';
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        };

        const handlePopState = (event) => {
            if (event.type === 'popstate') {
                const confirmed = window.confirm('Are you sure you want to exit the game?');
                if (confirmed) {
                    window.location.href = '/dashboard';
                } else {
                    return false;
                }
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        const numbers = generateRandomNumbers();
        initPuzzle(numbers);
        startTimer();
    }, []);

    useEffect(() => {
        if (isSolved() && clickCount > 0) {
            clearInterval(intervalRef.current);
            alert(`Congratulations! You solved the puzzle in ${clickCount} clicks and ${timeElapsed} seconds.`);
            window.location.reload();
        } else if (timeElapsed >= timeLimit) {
            clearInterval(intervalRef.current);
            alert(`Time's up! You exceeded the time limit of ${timeLimit} seconds.`);
            window.location.reload();
        }
    }, [clickCount, timeElapsed]);

    const generateRandomNumbers = () => {
        const excludedNumbers = [20, 30, 40, 50, 60, 70, 80, 90];
        const numbers = [];
        const min = 1;
        const max = 9;
        while (numbers.length < size * size - 1) {
            const num = Math.floor(Math.random() * (max - min + 1)) + min;
            if (!excludedNumbers.includes(num) && !numbers.includes(num)) {
                numbers.push(num);
            }
        }
        numbers.push(null);
        return numbers;
    };

    const initPuzzle = (numbers) => {
        numbers.sort(() => Math.random() - 0.5);
        const newGrid = [];
        for (let i = 0; i < size * size; i++) {
            const row = Math.floor(i / size);
            const col = i % size;
            newGrid.push({ number: numbers[i], row, col });
            if (numbers[i] === null) {
                setEmptyCell({ row, col });
            }
        }
        setGrid(newGrid);
    };

    const startTimer = () => {
        setTimerRunning(true);
    };

    useEffect(() => {
        if (timerRunning) {
            intervalRef.current = setInterval(() => {
                setTimeElapsed((prevTime) => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(intervalRef.current);
        }
        return () => clearInterval(intervalRef.current);
    }, [timerRunning]);

    const isSolved = () => {
        const sortedNumbers = grid
            .filter(({ number }) => number !== null)
            .map(({ number }) => number);

        for (let i = 1; i < sortedNumbers.length; i++) {
            if (sortedNumbers[i] < sortedNumbers[i - 1]) {
                return false;
            }
        }

        if (grid.length === 0 || grid[grid.length - 1].number !== null) {
            return false;
        }

        return true;
    };

    const movePiece = (clickedCell) => {
        if (!timerRunning) {
            startTimer();
        }
        const clickedIndex = clickedCell.row * size + clickedCell.col;
        const emptyIndex = emptyCell.row * size + emptyCell.col;
        if (clickCount >= maxClicks) {
            alert('Game Over! You reached the maximum number of clicks.');
            window.location.reload();
        }
        if ((Math.abs(clickedCell.row - emptyCell.row) === 1 && clickedCell.col === emptyCell.col) ||
            (Math.abs(clickedCell.col - emptyCell.col) === 1 && clickedCell.row === emptyCell.row)) {
            const updatedGrid = [...grid];
            [updatedGrid[clickedIndex].number, updatedGrid[emptyIndex].number] = [updatedGrid[emptyIndex].number, updatedGrid[clickedIndex].number];
            setGrid(updatedGrid);
            setEmptyCell(clickedCell);
            setClickCount(clickCount + 1);
        }
    };

    const renderGrid = () => {
        return grid.map(({ number, row, col }) => (
            <PuzzlePiece
                key={`${row}-${col}`}
                number={number}
                onClick={() => movePiece({ row, col })}
            />
        ));
    };

    const restartGame = () => {
        const numbers = generateRandomNumbers();
        initPuzzle(numbers);
        setClickCount(0);
        setTimeElapsed(0);
        startTimer();
    };

    return (
        <div>
            <InnerBanner />
            <div className="puzzle-game">
                <div className="game-content-main">
                    <div className="gc-1">
                        <b>No of Clicks Pending:</b> {Number(maxClicks - clickCount)}
                    </div>
                    <div className="gc-1">
                        <b>No of Seconds Pending:</b> {Number(timeLimit - timeElapsed)}
                    </div>
                </div>
                <div id="puzzle-container" 
                    style={{ 
                        gridTemplateColumns: `repeat(${size}, 1fr)`, 
                        gridTemplateRows: `repeat(${size}, 1fr)` }}
                >
                    {renderGrid()}
                </div>
                <div className="info"></div>
                <div>
                    <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6Lcvxt8pAAAAAG-gEixvHidHvY9ZxCy_A0PCxcgA" />
                    <button className="btn-3" style={{ background: '#5446d4', color: '#fff' }} onClick={handleReloadGame}>
                        Restart Game
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PuzzleGame;
