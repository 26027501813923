import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate, 
 useLocation

} from "react-router-dom";

import "./Styles.css";
import {jwtDecode} from "jwt-decode"; // This works in most cases, despite the warning
import axios from 'axios';
import Home from "./components/Home";
import Login from "./components/Login";
import PhoneSignUp from "./components/PhoneSignUp";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import Header from "./components/Header/Header";
import CommonHeader from "./components/Header/CommonHeader";
import Banner from "./components/Banner";
import SignUp from "./components/SignUp";
import Footer from "./components/Footer/Footer";
import MyAccountStatus from "./components/MyAccountStatus";
import ChangeAddress from "./components/ChangeAddress";
import Subscriptions from "./components/Subscriptions";
import Winnings from "./components/Winnings";
import Logout from "./components/Logout";
import ProfilePicModal from "./components/ProfilePicModal";
import Dashboard from "./components/Dashboard";
import Congratulations from "./components/Congratulations";
import ChangeSubscriptions from "./components/ChangeSubscriptions";
import ChangeAadharPan from "./components/ChangeAadharPan";
import ContactUs from "./components/ContactUs";
import TermsandConditions from "./components/TermsandConditions";
import AboutUs from "./components/AboutUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Gamep from "./components/Gamep";
import BoxPizzaGame from "./components/BoxPizzaGame";
import Testone from "./components/Testone";
import GameStepOne from "./components/GameStepOne";
import GameStepTwo from "./components/GameStepTwo";
import Identifier from "./components/identifier";
import QRcode from "./components/QRCode";
import BigBonanzaTermsandConditions from "./components/BigBonanzaTermsandConditions";
import { baseURL } from './services/Aut.post.js';
function App() {
  const [contactaddr, setContactAddr] = useState();
  const [getfooter, setgetfooter] = useState();

  const [gettcontactservices, setgettcontactservices] = useState();
  useEffect(() => {
    const getFooterText = async () => {
      const headers = {
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.get(`${baseURL}/userapi/getsitetext/`, { headers });
        
        setContactAddr(response.data.GetAddrtext);
        setgetfooter(response.data.GetFtext)
    
        setgettcontactservices(response.data.Getcontactservices)
        // console.log("this is ibe is best ")
        // console.log(response.data.GetAddrtext)
        // console.log(response.data.Getcontactservices)
        return response.data;
      } catch (error) {
        console.error('Error fetching contact address:', error);
        throw error;
      }
    };
    getFooterText();
  }, []);
 
  const [isTitle, setIsTitle] = useState("this i stest");
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("user")
  );
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState("");

  const userLoginStatus = JSON.parse(
    localStorage.getItem("userLoginStatus")
  ) || { signup_status: null };
  const [NewAuthentication, SetNewAuthentication] = useState(
    userLoginStatus.signup_status === 0
  );

  

  // start
  const [signupStatus, setSignupStatus] = useState(null);
  const [storedSignupStatus, setStoredSignupStatus] = useState(null);

  // Function to update the signupStatus and storedSignupStatus
  const handleSignupStatusUpdate = (signupStatus, storedSignupStatus) => {
    setSignupStatus(signupStatus);
    setStoredSignupStatus(storedSignupStatus);
  };
  //end

  useEffect(() => {
    // Update isAuthenticated based on signup_status
    if (userLoginStatus.signup_status === 0) {
      SetNewAuthentication(true);
    } else {
      SetNewAuthentication(false);
    }
  }, [userLoginStatus.signup_status]);

  const handleLogin = (token) => {
    localStorage.setItem("user", token);
    setIsAuthenticated(true);
  };
  const updatePhoneNumberVerification = () => {
    setIsPhoneNumberVerified(false);
  };

  // const refcode = "FREESUNDAY"
  // localStorage.setItem("refCode" , refcode)


  // Function to check if the access token has expired
 // Function to check if the access token has expired
const checkTokenExpiration = () => {
  const userLoginStatus = JSON.parse(localStorage.getItem("userLoginStatus"));

  if (userLoginStatus && userLoginStatus.access) {
    try {
      const decodedToken = jwtDecode(userLoginStatus.access);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

      if (decodedToken.exp < currentTime) {
        // Token has expired, show alert
        const userResponse = window.confirm(
          "Your session has expired. Please login again to continue."
        );

        if (userResponse) {
          // User clicked "OK"
          handleLogout(); // Handle logout and clean up
          window.location.href = "/phonesignup"; // Redirect to login page
        } else {
          // User clicked "Cancel"
          handleLogout(); // Log out and clean up
        }

        return false;
      }
      return true;
    } catch (error) {
      console.error("Error decoding token:", error);
      handleLogout(); // Logout on error with token
      return false;
    }
  }

  return false;
};

  

const handleLogout = () => {
    localStorage.clear(); // This removes all items from localStorage
    setIsAuthenticated(false);
};
  // const handleLogout = () => {
  //   localStorage.removeItem("user");
  //   localStorage.removeItem("userLoginStatus");
  //   localStorage.removeItem("userphonenumber");
  //   localStorage.removeItem("_grecaptcha");
  //   localStorage.removeItem("gridcount");
  //   localStorage.removeItem("contest_name");
  //   localStorage.removeItem("game_demo_link");
  //   localStorage.removeItem("sponcer_name");
  //   // for referal code removing
  //   localStorage.removeItem("originalRefCode");
  //   localStorage.removeItem("refCode");
  //   localStorage.removeItem("ref__Code");

  //   setIsAuthenticated(false);
  // };
  // const headerRoutes = ["/", "/phonesignup",];
  // const commenHeaderRoutes = ["/home", "/MyAccountStatus",];


  useEffect(() => {
    //Check token expiration on every page load
    const isTokenValid = checkTokenExpiration();
  
    if (!isTokenValid) {
      // Redirect to PhoneSignUp only once
      const tokenExpiredRedirect = () => {
        if (!window.location.href.includes("/phonesignup")) {
      
          window.location.href = "/phonesignup";
          
        }
      };
  
      tokenExpiredRedirect();
    }
  }, []);
  
  

  return (
    <Router>
      <div>
        <div className="min-height-1">
          <Header
            isAuthenticated={isAuthenticated}
            handleLogout={handleLogout}
          />
          <UserAuthContextProvider>
            <Routes>
              <Route
                path="/home"
                element={isAuthenticated ? <Home /> : <Navigate to="/" />}
              />

              <Route path="/" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route
                path="/phonesignup"
                element={
                  isAuthenticated ? (
                    <Navigate to="/Dashboard" />
                  ) : (
                    <PhoneSignUp
                      handleLogin={handleLogin}
                      handleSignupStatusUpdate={handleSignupStatusUpdate}
                    />
                  )
                }
              />

              {/* <Route path="/phonesignup" element={<PhoneSignUp />} />; */}

              {/* <Route path="/SignUp" element={isAuthenticated ? <Navigate to="/Dashboard" /> : <SignUp handleLogin={handleLogin} />} /> */}

              <Route exact path = "/SignUp" element={NewAuthentication? <SignUp handleLogin={handleLogin} /> : <Navigate to = "/"/>}/>
              {/* <Route path="/SignUp" element={<SignUp />} />  */}
 
              <Route
                path="/MyAccountStatus"
                element={
                  isAuthenticated ? <MyAccountStatus /> : <Navigate to="/" />
                }
              />
              <Route
                path="/ChangeAddress"
                element={
                  isAuthenticated ? <ChangeAddress /> : <Navigate to="/" />
                }
              />
              {/* <Route path="/Subscriptions" element={isAuthenticated ? <Subscriptions /> : <Navigate to="/" />} /> */}

              <Route path="/Subscriptions" element={<Subscriptions />} />
              <Route
                path="/Winnings"
                element={isAuthenticated ? <Winnings /> : <Navigate to="/" />}
              />
              <Route
                path="/ProfilePicModal"
                element={
                  isAuthenticated ? <ProfilePicModal /> : <Navigate to="/" />
                }
              />
              <Route
                path="/Dashboard"
                element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
              />



              <Route
                path="/Congratulations"
                element={
                  isAuthenticated ? <Congratulations /> : <Navigate to="/" />
                }
              />

              {/* <Route path="/ChangeSubscriptions" element={isAuthenticated ? <ChangeSubscriptions /> : <Navigate to="/" />} /> */}
              <Route
                path="/ChangeAadharPan"
                element={
                  isAuthenticated ? <ChangeAadharPan /> : <Navigate to="/" />
                }
              />

<Route
                path="/BigBonanzaTermsandConditions"
                element={isAuthenticated ? <BigBonanzaTermsandConditions /> : <Navigate to="/" />}
              />

              <Route
                path="/TermsandConditions"
                element={<TermsandConditions />}
              />
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              {/* <Route path="/ContactUs" element={<ContactUs />} contactaddr={contactaddr}/> */}
              <Route
  path="/ContactUs"
  element={<ContactUs contactaddr={contactaddr} gettcontactservices={gettcontactservices}  />}
/>

              <Route
                path="/ChangeSubscriptions"
                element={<ChangeSubscriptions />}
              />
              <Route
                path="/getsubscriptionvalues/:subscription_level/:subscription_type"
                element={<ChangeSubscriptions />}
              />

              <Route path="/Gamep" element={ isAuthenticated ? <Gamep /> : <Navigate to="/" />} />

                     
              {/* <Route exact path = "/QR_Code" element={<QRcode/>}/> */}
              {/* { 
            <Route
  path="/Gamep" 
  element={
    <>
      <CommonHeader />
      {isAuthenticated ? <Gamep /> : <Navigate to="/" />}
    </>
  }
/> } */}

              <Route path="/Testone" element={<Testone />} />
              <Route
                path="/GameStepOne"
                element={
                  isAuthenticated ? <GameStepOne /> : <Navigate to="/" />
                }
              />
              <Route
                path="/GameStepTwo"
                element={
                  isAuthenticated ? <GameStepTwo /> : <Navigate to="/" />
                }
              />
              {/* <Route path="/BoxPizzaGame" element={isAuthenticated ? <Navigate to="/home" /> : <BoxPizzaGame handleLogin={handleLogin} />} /> */}
              {/* <Route
  path="/SortingStarGame"
  element={
    <>
      <CommonHeader />
      { <BoxPizzaGame />}
    </>
  }
/> */}
              <Route path="/BoxPizzaGame" element={<BoxPizzaGame />} />
              {/* <Route path="/gamep" element={<Gamep />} /> */}

              <Route path="/identifier" element={<Identifier />} />

              <Route exact path="/QR_Code" element={<QRcode />} />
            </Routes>
          </UserAuthContextProvider>
        </div>
        <Footer getfooter={getfooter} />
      </div>
    </Router>
  );
}

export default App;