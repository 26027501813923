import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import ModalContent from "../ModalContent";
import Logo from "../images/logo.png";
import "./Header.css";


function Header({ isAuthenticated, handleLogout }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [Exit, setExit] = useState(false); // Exit state
  const navbarRef = useRef(null);

  const currentPath = window.location.pathname;

  useEffect(() => {
    // Check the path and show Exit button if on the /Gamep page
    if (currentPath === '/Gamep') {
      setExit(true);
    } else {
      setExit(false);
    }

    const handleBeforeUnload = (event) => {
      if (currentPath === '/Gamep') {
        event.preventDefault();
        event.returnValue = ''; // Standard way to show a prompt on unload
        localStorage.setItem('shouldRedirect', 'true'); // Store the redirect flag
      }
    };

    

    const handlePopState = () => {
      if (currentPath === '/Gamep') {
        if (window.confirm('Are you sure you want to go back?')) {
          localStorage.setItem('shouldRedirect', 'true'); // Store the redirect flag
          navigate('/Dashboard'); // Navigate to dashboard
        } else {
          localStorage.setItem('shouldRedirect', 'false'); // Do not redirect
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    const redirectFlag = localStorage.getItem('shouldRedirect');
    if (redirectFlag === 'true') {
      navigate('/Dashboard');
      localStorage.removeItem('shouldRedirect'); // Clear the flag after redirection
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [currentPath, navigate]);

  const handleLogoClick = () => {
    if (currentPath === '/Gamep' && !window.confirm('Are you sure you want to exit the game?')) {
      return;
    }
    navigate('/');
  };

  const handleMyAccountClick = () => {
    if (currentPath === '/Gamep' && !window.confirm('Are you sure you want to exit the game?')) {
      return;
    }
    setShowNavbar(prev => !prev);
  };

  const handleRedirect = () => {
    if (currentPath === '/Gamep' && !window.confirm('Are you sure you want to exit the game?')) {
      return;
    }
    setShowNavbar(false);
    handleLogout();
    navigate('/home');
  };

  const handleNavigation = (path, message) => {
    if (currentPath === '/Gamep' && !window.confirm(message)) {
      return;
    }
    setShowNavbar(false);
    navigate(path);
  };

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setShowNavbar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="header">
        <div className="wrapper">
          <div className="nav-wrapper">
            <div className="nav-1">
              <img 
                src={Logo} 
                className="logo-div" 
                alt={Logo} 
                width="170" 
                onClick={handleLogoClick} 
                style={{ cursor: 'pointer' }}
              />
            </div>
            {Exit ? ( // Show Exit button if the user is on /Gamep page
              
              <div className="nav-2">
                 <div className="nav-main">
                 <div>
                <button className="btn-3" onClick={() => handleNavigation('/dashboard', 'Are you sure you want to exit the game?')}>Exit Contest</button>
              </div>
                 </div>
              </div>
            ) : (
              <div className="nav-2">
                <div className="nav-main">
                  {isAuthenticated ? (
                    <span>
                      <button className="btn-3" onClick={() => setShowNavbar(!showNavbar)}>My Account</button>
                    </span>
                  ) : (
                    <Link className="btn-3" to="/phonesignup">Login</Link>
                  )}
                  {showNavbar && (
                    <div className="MyAccountNav" ref={navbarRef}>
                      <ul>
                        <li>
                          <a href="#" onClick={() => handleNavigation('/Dashboard', 'Are you sure you want to exit the game?')}>Dashboard</a>
                        </li>
                        <li>
                          <a href="#" onClick={() => handleNavigation('/Home', 'Are you sure you want to exit the game?')}>My Profile</a>
                        </li>
                        <li>
                          <a href="#" onClick={() => handleNavigation('/MyAccountStatus', 'Are you sure you want to exit the game?')}>Status</a>
                        </li>
                        <li>
                          <a href="#" onClick={() => handleNavigation('/Winnings', 'Are you sure you want to exit the game?')}>Winnings</a>
                        </li>
                        <li>
                          <a href="#" onClick={handleRedirect}>Logout</a>
                        </li>
                      </ul>
                    </div>
                  )}
                  {showModal &&
                    createPortal(
                      <ModalContent onClose={() => setShowModal(false)} />,
                      document.body
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default Header;
