// TitleUpdater.js
import React, { useEffect, useState } from 'react';
import { baseURL } from "./services/Aut.post.js";
const TitleUpdater = () => {
  const [title, setTitle] = useState('Loading...');

  useEffect(() => {
    fetch(`${baseURL}/userapi/getsitetext/`)  // Ensure this endpoint matches your backend route
      .then(response => response.json())
      .then(data => {
        if (data.GetHtext) {
          setTitle(data.GetHtext);
          document.title = data.GetHtext;
        }
      })
      .catch(error => {
        console.error('Error fetching title:', error);
      });
  }, []);

  return null; // This component doesn't need to render anything
};

export default TitleUpdater;
