import React, { useEffect, useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import InnerBanner from "./InnerBanner";
import { Link } from "react-router-dom";
import "./Styles/ContactUs.css";
import { Form, Alert } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../services/Aut.post.js";

function ContactUs({ contactaddr, gettcontactservices }) {

  //const options = gettcontactservices.split(",");
  const options = (gettcontactservices || "").split(",");


  const recaptchaRef = useRef(null); // Ref for the reCAPTCHA

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phonenumber: "",
    supporttype:"",
    msg: "",
  });

  const [errors, setErrors] = useState({});
  const [errorsumsg, setErrorsumsg] = useState();

  // Handle form input changes
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Form validation function
  const validate = () => {
    const newErrors = {};
    if (!formData.fullname) newErrors.fullname = "Name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.phonenumber)
      newErrors.phonenumber = "Phone number is required.";
    if (!formData.supporttype) newErrors.supporttype = "Support Type is required.";
    if (!formData.msg) newErrors.msg = "Message is required.";

    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const recaptchaValue = await recaptchaRef.current.executeAsync(); // Execute invisible reCAPTCHA
      recaptchaRef.current.reset(); // Reset the reCAPTCHA after execution

      setErrors({}); // Clear any existing errors

      try {
        const response = await axios.post(
          `${baseURL}/userapi/submit-contact-form/`,
          { ...formData, recaptchaToken: recaptchaValue } // Send token with form data
        );
        console.log("Form submitted successfully:", response.data);
        setErrorsumsg("Form submitted successfully");
        setFormData({
          fullname: '',
          email: '',
          phonenumber: '',
          supporttype:'',
          msg: ''
        });
      } catch (error) {
        console.error("Error submitting form:", error);
        setErrorsumsg("Error submitting form");
      }
    }
  };

  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 Contact-wrap">
        <div className="wrapper">
          <h1>Contact US</h1>

          <div className="contact-address-wrap">
          <div className="contact-address-1">
  <div>
         
            <h4>Address:</h4>
            <p dangerouslySetInnerHTML={{ __html: contactaddr }} />
         
             {/* 5/12, PKpalli,<br></br>Prakasham, Andhra Pradesh,<br></br>
            Pin code: 523211 */}
          </div>


  </div>
            <div className="contact-address-2">
              <div id="returnmsg">{errorsumsg}</div>

              <div>
                <form onSubmit={handleSubmit}>
                  <div className="Contact-form-wrap">
                    <div className="reg-1">
                      <b>Name</b>
                      <input
                        type="text"
                        name="fullname"
                        className="text-f-1"
                        value={formData.fullname}
                        onChange={handleInputChange}
                      />
                      {errors.fullname && (
                        <p className="error">{errors.fullname}</p>
                      )}
                    </div>

                    <div className="reg-1">
                      <b>E-mail</b>
                      <input
                        type="text"
                        name="email"
                        className="text-f-1"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      {errors.email && <p className="error">{errors.email}</p>}
                    </div>

                    <div className="reg-1 w-100">
                      <b>Phone</b>
                      <input
                        type="text"
                        name="phonenumber"
                        className="text-f-1"
                        value={formData.phonenumber}
                        onChange={handleInputChange}
                      />
                      {errors.phonenumber && (
                        <p className="error">{errors.phonenumber}</p>
                      )}
                    </div>

                    <div className="reg-1 w-100">
                      <b>Support Type</b>
                   <select className="text-f-1" name="supporttype"  onChange={handleInputChange}
                    value={formData.supporttype}
                   >
                     <option>Select</option>
                     {options.map((option, index) => (
    <option key={index} value={option}>
      {option}
    </option>
  ))}

                


                   </select>
                      {errors.supporttype && (
                        <p className="error">{errors.supporttype}</p>
                      )}
                    </div>

                    <div className="reg-1 w-100">
                      <b>Message</b>
                      <textarea
                        className="text-f-1"
                        name="msg"
                        value={formData.msg}
                        onChange={handleInputChange}
                      />
                      {errors.msg && <p className="error">{errors.msg}</p>}
                    </div>

                    <div className="reg-1 w-100 text-center">
                      {/* <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="YOUR_RECAPTCHA_SITE_KEY" 
                        size="invisible"
                      /> */}
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6Lcvxt8pAAAAAG-gEixvHidHvY9ZxCy_A0PCxcgA"
                      />
                      <button className="btn-2" type="submit">
                        Submit
                      </button>
                      &nbsp;&nbsp;
                      <Link to="/MyAccountStatus">
                        <button className="btn-1" type="button">
                          Cancel
                        </button>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}
export default ContactUs;
