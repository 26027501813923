import React, { useState } from "react";
import InnerBanner from "./InnerBanner";
import "./Styles/Tc.css";

function TermsandConditions() {
  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper">
          <h1>Terms and Conditions </h1>
          <div className="TC-Text">
            <div className="TermsCondtnsBox">
              {/* <h5>Last Updated: 1 October, 2024</h5> */}
              <ol>
                <li>
                  <h3>Big Bonanza</h3>
                  <div>
                    <div>
                      Big Bonanza is the flagship brand of XENFORD INTERNET PRIVATE LIMITED ("Big Bonanza"). Through Big Bonanza,
                      along with its sub-pages, and the Big Bonanza App, Big
                      Bonanza offers cricket based, football based, basketball
                      based, volleyball based, hockey based, baseball based,
                      handball based, American football based and kabaddi based
                      online fantasy games. Big Bonanza as used herein shall be
                      construed as a collective reference to Big Bonanza and the
                      Big Bonanza App.
                    </div>
                  </div>
                </li>
                <li>
                  <h3>Usage of Big Bonanza</h3>
                  <div>
                    <ul>
                      <li>
                        Any person ("<strong>User</strong>") accessing Big
                        Bonanza or the Big Bonanza App ('Big Bonanza platform')
                        for participating in the various contests and games
                        (including fantasy games), available on Big Bonanza
                        platform ("<strong>Contest(s)</strong>") ('Big Bonanza
                        Services') shall be bound by these Terms and Conditions,
                        and all other rules, regulations and terms of use
                        referred to herein or provided by Big Bonanza in
                        relation to any Big Bonanza Services.
                      </li>
                      <li>
                        Big Bonanza shall be entitled to modify these Terms and
                        Conditions, rules, regulations and terms of use referred
                        to herein or provided by Big Bonanza in relation to any
                        Big Bonanza Services, at any time, by posting the same
                        on Big Bonanza. Use of Big Bonanza constitutes the
                        User's acceptance of such Terms and Conditions, rules,
                        regulations and terms of use referred to herein or
                        provided by Big Bonanza in relation to any Big Bonanza
                        Services, as may be amended from time to time. Big
                        Bonanza may, at its sole discretion, also notify the
                        User of any change or modification in these Terms and
                        Conditions, rules, regulations and terms of use referred
                        to herein or provided by Big Bonanza, by way of sending
                        an email to the User's registered email address or
                        posting notifications in the User accounts. The User may
                        then exercise the options provided in such an email or
                        notification to indicate non-acceptance of the modified
                        Terms and Conditions, rules, regulations and terms of
                        use referred to herein or provided by Big Bonanza. If
                        such options are not exercised by the User within the
                        time frame prescribed in the email or notification, the
                        User will be deemed to have accepted the modified Terms
                        and Conditions, rules, regulations and terms of use
                        referred to herein or provided by Big Bonanza
                      </li>
                      <li>
                        Certain Big Bonanza Services being provided on Big
                        Bonanza may be subject to additional rules and
                        regulations set down in that respect. To the extent that
                        these Terms and Conditions are inconsistent with the
                        additional conditions set down, the additional
                        conditions shall prevail
                      </li>
                      <li>
                        Big Bonanza may, at its sole and absolute discretion:
                        <ul>
                          <li>
                            Restrict, suspend, or terminate any User's access to
                            all or any part of Big Bonanza or Big Bonanza
                            Platform Services;
                          </li>
                          <li>
                            Change, suspend, or discontinue all or any part of
                            the Big Bonanza Platform Services;
                          </li>
                          <li>
                            Reject, move, or remove any material that may be
                            submitted by a User;
                          </li>
                          <li>
                            Move or remove any content that is available on Big
                            Bonanza Platform;
                          </li>
                          <li>
                            Establish general practices and limits concerning
                            use of Big Bonanza Platform;
                          </li>
                          <li>
                            Offer discounts to its users in form it deems fit ("
                            <strong>Discount Bonus</strong>"). All such
                            discounts shall be credited in a separate account
                            called as Discount Bonus Account
                          </li>
                          <li>
                            Revise or make additions and/or deletions to the
                            roster of players available for selection in a
                            Contest on account of revisions to the roster of
                            players involved in the relevant Sports Event;
                          </li>
                          <li>
                            Assign its rights and liabilities to all User
                            accounts hereunder to any entity (post such
                            assignment intimation of such assignment shall be
                            sent to all Users to their registered email ids)
                          </li>
                        </ul>
                      </li>
                      <li>
                        In the event any User breaches, or Big Bonanza
                        reasonably believes that such User has breached these
                        Terms and Conditions, or has illegally or improperly
                        used Big Bonanza or the Big Bonanza Services, Big
                        Bonanza may, at its sole and absolute discretion, and
                        without any notice to the User, restrict, suspend or
                        terminate such User's access to all or any part of Big
                        Bonanza Contests or the Big Bonanza Platform, deactivate
                        or delete the User's account and all related information
                        on the account, delete any content posted by the User on
                        Big Bonanza and further, take technical and legal steps
                        as it deems necessary
                      </li>
                      <li>
                        If Big Bonanza charges its Users a platform fee in
                        respect of any Big Bonanza Services, Big Bonanza shall,
                        without delay, repay such platform fee in the event of
                        suspension or removal of the User's account or Big
                        Bonanza Services on account of any negligence or
                        deficiency on the part of Big Bonanza, but not if such
                        suspension or removal is effected due to:
                        <ul>
                          <li>
                            any breach or inadequate performance by the User of
                            any of these Terms and Conditions; or
                          </li>
                          <li>
                            any circumstances beyond the reasonable control of
                            Big Bonanza.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Users consent to receiving communications such as
                        announcements, administrative messages and
                        advertisements from Big Bonanza or any of its partners,
                        licensors or associates.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Intellectual Property</h3>
                  <div>
                    <ul>
                      <li>
                        Big Bonanza includes a combination of content created by
                        Big Bonanza, its partners, affiliates, licensors,
                        associates and/or Users. The intellectual property
                        rights ("<strong>Intellectual Property Rights</strong>")
                        in all software underlying Big Bonanza and the Big
                        Bonanza Platform and material published on Big Bonanza,
                        including (but not limited to) games, Contests,
                        software, advertisements, written content, photographs,
                        graphics, images, illustrations, marks, logos, audio or
                        video clippings and Flash animation, is owned by Big
                        Bonanza, its partners, licensors and/or associates.
                        Users may not modify, publish, transmit, participate in
                        the transfer or sale of, reproduce, create derivative
                        works of, distribute, publicly perform, publicly
                        display, or in any way exploit any of the materials or
                        content on Big Bonanza either in whole or in part
                        without express written license from Big Bonanza
                      </li>
                      <li>
                        Users may request permission to use any Big Bonanza
                        content by writing in to{" "}
                        <a href="#">Big Bonanza Helpdesk</a>.
                      </li>
                      <li>
                        Users are solely responsible for all materials (whether
                        publicly posted or privately transmitted) that they
                        upload, post, e-mail, transmit, or otherwise make
                        available on Big Bonanza ("
                        <strong>Users' Content</strong>"). Each User represents
                        and warrants that he/she owns all Intellectual Property
                        Rights in the User's Content and that no part of the
                        User's Content infringes any third party rights. Users
                        further confirm and undertake to not display or use of
                        the names, logos, marks, labels, trademarks, copyrights
                        or intellectual and proprietary rights of any third
                        party on Big Bonanza. Users agree to indemnify and hold
                        harmless Big Bonanza, its directors, employees,
                        affiliates and assigns against all costs, damages, loss
                        and harm including towards litigation costs and counsel
                        fees, in respect of any third party claims that may be
                        initiated including for infringement of Intellectual
                        Property Rights arising out of such display or use of
                        the names, logos, marks, labels, trademarks, copyrights
                        or intellectual and proprietary rights on Big Bonanza,
                        by such User or through the User's commissions or
                        omissions
                      </li>
                      <li>
                        Users hereby grant to Big Bonanza and its affiliates,
                        partners, licensors and associates a worldwide,
                        irrevocable, royalty-free, non-exclusive, sub-licensable
                        license to use, reproduce, create derivative works of,
                        distribute, publicly perform, publicly display,
                        transfer, transmit, and/or publish Users' Content for
                        any of the following purposes:
                        <ul>
                          <li>displaying Users' Content on Big Bonanza</li>
                          <li>
                            distributing Users' Content, either electronically
                            or via other media, to other Users seeking to
                            download or otherwise acquire it, and/or
                          </li>
                          <li>
                            storing Users' Content in a remote database
                            accessible by end users, for a charge.
                          </li>
                          <li>
                            This license shall apply to the distribution and the
                            storage of Users' Content in any form, medium, or
                            technology.
                          </li>
                        </ul>
                      </li>
                      <li>
                        All names, logos, marks, labels, trademarks, copyrights
                        or intellectual and proprietary rights on Big Bonanza(s)
                        belonging to any person (including User), entity or
                        third party are recognized as proprietary to the
                        respective owners and any claims, controversy or issues
                        against these names, logos, marks, labels, trademarks,
                        copyrights or intellectual and proprietary rights must
                        be directly addressed to the respective parties under
                        notice to Big Bonanza.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Third Party Sites, Services and Products</h3>
                  <div>
                    <ul>
                      <li>
                        Big Bonanza may contain links to other Internet sites
                        owned and operated by third parties. Users' use of each
                        of those sites is subject to the conditions, if any,
                        posted by the sites. Big Bonanza does not exercise
                        control over any Internet sites apart from Big
                        Bonanzaand cannot be held responsible for any content
                        residing in any third-party Internet site. Big Bonanza's
                        inclusion of third-party content or links to third-party
                        Internet sites is not an endorsement by Big Bonanza of
                        such third-party Internet site.
                      </li>
                      <li>
                        Users' correspondence, transactions/offers or related
                        activities with third parties, including payment
                        providers and verification service providers, are solely
                        between the User and that third party. Users'
                        correspondence, transactions and usage of the
                        services/offers of such third party shall be subject to
                        the terms and conditions, policies and other service
                        terms adopted/implemented by such third party, and the
                        User shall be solely responsible for reviewing the same
                        prior to transacting or availing of the services/offers
                        of such third party. User agrees that Big Bonanza will
                        not be responsible or liable for any loss or damage of
                        any sort incurred as a result of any such
                        transactions/offers with third parties. Any questions,
                        complaints, or claims related to any third party product
                        or service should be directed to the appropriate vendor.
                      </li>
                      <li>
                        Big Bonanza contains content that is created by Big
                        Bonanza as well as content provided by third parties.
                        Big Bonanza does not guarantee the accuracy, integrity,
                        quality of the content provided by third parties and
                        such content may not relied upon by the Users in
                        utilizing the Big Bonanza Services provided on Big
                        Bonanza including while participating in any of the
                        contests hosted on Big Bonanza.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Privacy Policy</h3>
                  <div>
                    <div>
                      All information collected from Users, such as registration
                      and credit card information, is subject to Big Bonanza's
                      Privacy Policy which is available at{" "}
                      <a href="#">Privacy Policy </a>
                    </div>
                    <div></div>
                  </div>
                </li>
                <li>
                  <h3>User Conduct</h3>
                  <div>
                    <ul>
                      <li>
                        Users agree to abide by these Terms and Conditions and
                        all other rules, regulations and terms of use of the
                        Website. In the event User does not abide by these Terms
                        and Conditions and all other rules, regulations and
                        terms of use, Big Bonanza may, at its sole and absolute
                        discretion, take necessary remedial action, including
                        but not limited to:
                        <ul>
                          <li>
                            restricting, suspending, or terminating any User's
                            access to all or any part of Big Bonanza Services;
                          </li>
                          <li>
                            deactivating or deleting a User's account and all
                            related information and files on the account. Any
                            amount remaining unused in the User's Amount
                            Unutilised Account or Winnings Account (subject to
                            deduction of applicable TDS with effect from 1st
                            April 2023 as applicable) on the date of
                            deactivation or deletion shall be transferred to the
                            User's bank account on record with Big Bonanza
                            subject to a processing fee (if any) applicable on
                            such transfers as set out herein; or
                          </li>
                          <li>
                            refraining from awarding any prize(s) to such User.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Users agree to provide true, accurate, current and
                        complete information at the time of registration and at
                        all other times (as required by Big Bonanza). Users
                        further agree to update and keep updated their
                        registration information
                      </li>
                      <li>
                        Users agree and acknowledge that the address related
                        details provided, if any, by the user shall be treated
                        as “Address on record” of the user for the purposes of
                        Goods and Service Tax Act. In the event of the change in
                        such details, the user shall promptly update his/her
                        profile on the Big Bonanza platform or write to Big
                        Bonanza at <a href="#">helpdesk</a>.
                      </li>
                      <li>
                        A User shall not register or operate more than one User
                        account with Big Bonanza.
                      </li>
                      <li>
                        Users agree to ensure that they can receive all
                        communication from Big Bonanza by marking e-mails or
                        sending SMSs from Big Bonanza as part of their "safe
                        senders" list. Big Bonanza shall not be held liable if
                        any e-mail/SMS remains unread by a User as a result of
                        such e-mail getting delivered to the User's junk or spam
                        folder.
                      </li>
                      <li>
                        Users are responsible for maintaining the
                        confidentiality of their accounts and passwords. Users
                        agree to immediately notify Big Bonanza of any
                        unauthorized use of accounts or any other breach of
                        security.
                      </li>
                      <li>
                        Users agree to exit/log-out of their accounts at the end
                        of each session. Big Bonanza shall not be responsible
                        for any loss or damage that may result if the User fails
                        to comply with these requirements.
                      </li>
                      <li>
                        Users agree not to use cheats, exploits, automation,
                        software, bots, hacks or any unauthorised third party
                        software designed to modify or interfere with Big
                        Bonanza Services and/or Big Bonanza experience or assist
                        in such activity.
                      </li>
                      <li>
                        Users agree not to copy, modify, rent, lease, loan,
                        sell, assign, distribute, reverse engineer, grant a
                        security interest in, or otherwise transfer any right to
                        the technology or software underlying Big Bonanza or Big
                        Bonanza’s Services.
                      </li>
                      <li>
                        Users agree that without Big Bonanza's express written
                        consent, they shall not modify or cause to be modified
                        any files or software that are part of Big Bonanza's
                        Services.
                      </li>
                      <li>
                        Users agree not to disrupt, overburden, or aid or assist
                        in the disruption or overburdening of (a) any computer
                        or server used to offer or support Big Bonanza or the
                        Big Bonanza’s Services (each a "Server"); or (2) the
                        enjoyment of Big Bonanza Services by any other User or
                        person.
                      </li>
                      <li>
                        Users agree not to institute, assist or become involved
                        in any type of attack, including without limitation to
                        distribution of a virus, denial of service, or other
                        attempts to disrupt Big Bonanza Services or any other
                        person's use or enjoyment of Big Bonanza Services.
                      </li>
                      <li>
                        Users shall not attempt to gain unauthorised access to
                        the User accounts, Servers or networks connected to Big
                        Bonanza Services by any means other than the User
                        interface provided by Big Bonanza, including but not
                        limited to, by circumventing or modifying, attempting to
                        circumvent or modify, or encouraging or assisting any
                        other person to circumvent or modify, any security,
                        technology, device, or software that underlies or is
                        part of Big Bonanza Services.
                      </li>
                      <li>
                        User shall not to publish any content that is patently
                        false and untrue, and is written or published in any
                        form, with the intent to mislead or harass a person,
                        entity or agency for financial gain or to cause any
                        injury to any person.
                      </li>
                      <li>
                        Without limiting the foregoing, Users agree not to use
                        Big Bonanza for any of the following:
                        <ul>
                          <li>
                            To engage in any obscene, offensive, indecent,
                            racial, communal, anti-national, objectionable,
                            defamatory or abusive action or communication;
                          </li>
                          <li>
                            To harass, stalk, threaten, or otherwise violate any
                            legal rights of other individuals;
                          </li>
                          <li>
                            To publish, post, upload, e-mail, distribute, or
                            disseminate (collectively, "Transmit") any
                            inappropriate, profane, defamatory, infringing,
                            obscene, indecent, or unlawful content;
                          </li>
                          <li>
                            To Transmit files that contain viruses, corrupted
                            files, or any other similar software or programs
                            that may damage or adversely affect the operation of
                            another person's computer, Big Bonanza, any
                            software, hardware, or telecommunications equipment;
                          </li>
                          <li>
                            To advertise, offer or sell any goods or services
                            for any commercial purpose on Big Bonanza without
                            the express written consent of Big Bonanza;
                          </li>

                          <li>
                            To download any file, recompile or disassemble or
                            otherwise affect our products that you know or
                            reasonably should know cannot be legally obtained in
                            such manner;
                          </li>
                          <li>
                            To falsify or delete any author attributions, legal
                            or other proper notices or proprietary designations
                            or labels of the origin or the source of software or
                            other material;
                          </li>
                          <li>
                            To restrict or inhibit any other user from using and
                            enjoying any public area within our sites;
                          </li>
                          <li>
                            To collect or store personal information about other
                            Users;
                          </li>
                          <li>
                            To interfere with or disrupt Big Bonanza, servers,
                            or networks;
                          </li>
                          <li>
                            To impersonate any person or entity, including, but
                            not limited to, a representative of Big Bonanza, or
                            falsely state or otherwise misrepresent User's
                            affiliation with a person or entity;
                          </li>
                          <li>
                            To forge headers or manipulate identifiers or other
                            data in order to disguise the origin of any content
                            transmitted through Big Bonanza or to manipulate
                            User's presence on Big Bonanza(s);
                          </li>
                          <li>
                            To take any action that imposes an unreasonably or
                            disproportionately large load on our infrastructure;
                          </li>
                          <li>
                            To engage in any illegal activities. You agree to
                            use our bulletin board services, chat areas, news
                            groups, forums, communities and/or message or
                            communication facilities (collectively, the
                            "Forums") only to send and receive messages and
                            material that are proper and related to that
                            particular Forum.
                          </li>
                          <li>
                            To engage in any action that threatens the unity,
                            integrity, defence, security or sovereignty of
                            India, friendly relations with foreign States, or
                            public order, or causes incitement to the commission
                            of any cognisable offence or prevents investigation
                            of any offence or is insulting other nation.
                          </li>
                        </ul>
                      </li>
                      <li>
                        If a User chooses a username that, in Big Bonanza's
                        considered opinion is obscene, indecent, abusive or that
                        might subject Big Bonanza to public disparagement or
                        scorn, or a name which is an official
                        team/league/franchise names and/or name of any sporting
                        personality, as the case may be, Big Bonanza reserves
                        the right, without prior notice to the User, to restrict
                        usage of such names, which in Big Bonanza’s opinion fall
                        within any of the said categories and/or change such
                        username and intimate the User or delete such username
                        and posts from Big Bonanza, deny such User access to Big
                        Bonanza, or any combination of these options.
                      </li>
                      <li>
                        Unauthorized access to Big Bonanza is a breach of these
                        Terms and Conditions, and a violation of the law. Users
                        agree not to access Big Bonanza by any means other than
                        through the interface that is provided by Big Bonanza
                        for use in accessing Big Bonanza. Users agree not to use
                        any automated means, including, without limitation,
                        agents, robots, scripts, or spiders, to access, monitor,
                        or copy any part of our sites, except those automated
                        means that we have approved in advance and in writing.
                      </li>
                      <li>
                        Use of Big Bonanza is subject to existing laws and legal
                        processes. Nothing contained in these Terms and
                        Conditions shall limit Big Bonanza's right to comply
                        with governmental, court, and law-enforcement requests
                        or requirements relating to Users' use of Big Bonanza.
                      </li>

                      <li>
                        Users may reach out to Big Bonanza through -
                        <ul>
                          <li>
                            <a href="#" rel="nofollow">
                              Helpdesk
                            </a>{" "}
                            if the user has any concerns with regard to a match
                            and/or contest within Forty Eight (48) hours of
                            winner declaration for the concerned contest.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Persons below the age of eighteen (18) years are not
                        allowed to participate on any of the paid contests,
                        games (by whatever name called) on the Big Bonanza
                        Platform. The Users will have to disclose their real age
                        at the time of getting access into the Big Bonanza
                        Platform.
                      </li>
                      <li>
                        Big Bonanza may not be held responsible for any content
                        contributed by Users on the Big Bonanza.
                      </li>
                      <li>
                        User hereby confirms that he / she is participating in a
                        contest in personal capacity and not in the course of
                        business and /or profession.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Conditions of Participation:</h3>
                  <div>
                    <div>
                      By entering a Contest, user agrees to be bound by these
                      Terms and the decisions of Big Bonanza. Subject to the
                      terms and conditions stipulated herein below, the Big
                      Bonanza, at its sole discretion, may disqualify any user
                      from a Contest, refuse to award benefits or prizes and
                      require the return of any prizes, if the user engages in
                      unfair conduct, which the Big Bonanza deems to be
                      improper, unfair or otherwise adverse to the operation of
                      the Contest or is in any way detrimental to other Users
                      which includes, but is not limited to:
                    </div>
                    <ul>
                      <li>
                        Falsifying ones’ own personal information (including,
                        but not limited to, name, email address, bank account
                        details and/or any other information or documentation as
                        may be requested by Big Bonanza to enter a contest
                        and/or claim a prize/winning.;
                      </li>
                      <li>
                        Engaging in any type of financial fraud or
                        misrepresentation including unauthorized use of
                        credit/debit instruments, payment wallet accounts etc.
                        to enter a Contest or claim a prize. It is expressly
                        clarified that the onus to prove otherwise shall solely
                        lie on the user.;
                      </li>
                      <li>
                        Colluding with any other user(s) or engaging in any type
                        of syndicate play;
                      </li>
                      <li>
                        Any violation of Contest rules or the Terms of Use;
                      </li>
                      <li>
                        Accumulating points or prizes through unauthorized
                        methods such as automated bots, or other automated
                        means;
                      </li>
                      <li>
                        Using automated means (including but not limited to
                        harvesting bots, robots, parser, spiders or screen
                        scrapers) to obtain, collect or access any information
                        on the Website or of any User for any purpose
                      </li>
                      <li>
                        Any type of Discount Bonus misuse, misuse of the Invite
                        Friends program, or misuse of any other offers or
                        promotions;
                      </li>
                      <li>
                        Tampering with the administration of a Contest or trying
                        to in any way tamper with the computer programs or any
                        security measure associated with a Contest;
                      </li>
                      <li>
                        Obtaining other users’ information without their express
                        consent and/or knowledge and/or spamming other users
                        (Spamming may include but shall not be limited to send
                        unsolicited emails to users, sending bulk emails to Big
                        Bonanza Users, sending unwarranted email content either
                        to selected Users or in bulk); or
                      </li>
                      <li>
                        Abusing the Website in any way (‘unparliamentary
                        language, slangs or disrespectful words’ are some of the
                        examples of Abuse)
                      </li>
                    </ul>
                    <div>
                      It is clarified that in case a User is found to be in
                      violation of this policy, Big Bonanza reserves its right
                      to initiate appropriate Civil/Criminal remedies as it may
                      be advised other than forfeiture and/or recovery of prize
                      money if any.
                    </div>
                  </div>
                </li>
                <li>
                  <h3>Registration for a contest</h3>
                  <div>
                    <ul>
                      <li>
                        In order to register for the Contest(s), Participants
                        are required to accurately provide the following
                        information:
                        <ul>
                          <li>Full Name</li>
                          <li>Team Name(s)</li>
                          <li>E-mail address</li>
                          <li>Password</li>
                          <li>State of Residence</li>
                          <li>Gender</li>
                          <li>Date of birth</li>
                        </ul>
                      </li>
                      <li>
                        Participants are also required to confirm that they have
                        read, and shall abide by, these Terms and Conditions.
                      </li>
                      <li>
                        In the event a Participant indicates, while entering an
                        address, that he/she is a resident of either Andhra
                        Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana
                        such Participant will not be permitted to proceed to
                        sign up for any match in the paid version of the Contest
                        as described below.
                      </li>
                      <li>
                        Once the Participants have entered the above
                        information, and clicked on the “register” tab, the
                        Participants account is created with the above
                        information.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Contest(s), Participation and Prizes</h3>
                  <div>
                    <ul>
                      <li>
                        As part of its services, Big Bonanza may make available
                        the contest(s) on the Big Bonanza platform.
                      </li>
                      <li>
                        Currently, following contests are made available on Big
                        Bonanza platform: 1) A{" "}
                        <a href="#" title="">
                          Fantasy Cricket
                        </a>{" "}
                        game, 2) A fantasy football game, 3) A fantasy kabaddi
                        game, 4) A fantasy basketball game, 5) A fantasy
                        volleyball game, 6) A fantasy hockey game, 7) A fantasy
                        baseball game, 8) A fantasy American football game and
                        9) A fantasy handball game. Individual users wishing to
                        participate in the such contest ("“Participants"”) are
                        invited to create their own fantasy teams ("“Team/s"”)
                        consisting of real life cricketers, footballers,
                        basketball players, volleyball players, hockey players,
                        baseball players, handball players, American football
                        players or kabaddi players (as applicable) involved in
                        the real-life
                        cricket/football/kabaddi/volleyball/hockey/basketball/baseball/handball/American
                        football match (as applicable), series or tournament
                        (each a "Sport Event") to which the fantasy game
                        relates. Big Bonanza offers its platform to Participants
                        for fantasy game Contest(s) being created relating to
                        each Sport Event, and Participants can participate in
                        such Contest(s) with their Teams.
                      </li>
                      <li>
                        Depending upon the circumstances of each match, the
                        participants can edit their teams till the official
                        match start time as declared by the officials of the
                        Sport Event or adjusted deadline, as specified below.
                        <ul>
                          <li>
                            Big Bonanza reserves the right to abandon a specific
                            round or adjust the deadline of a round in certain
                            specific, uncertain scenarios, which are beyond Big
                            Bonanza’s reasonable control, including but not
                            limited to the ones’ mentioned herein below:
                            <ol>
                              <li>
                                <h5>
                                  Actual match start time is before the official
                                  deadline:
                                </h5>
                                <ul>
                                  <li>
                                    All sports other than Cricket-
                                    <ul>
                                      <li>
                                        Big Bonanza reserves the right to adjust
                                        the deadline to a maximum of 10 minutes
                                        post actual match start time. In case
                                        the actual match start time is more than
                                        10 minutes of official deadline, the
                                        contest will be abandoned.
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    Cricket -
                                    <ul>
                                      <li>
                                        Big Bonanza reserves the right to adjust
                                        the deadline by a Maximum of 10 minutes
                                        or 3 overs bowled, whichever is less,
                                        before the official match start time.
                                      </li>
                                      <li>
                                        In cases where official match time
                                        cannot be verified by Big Bonanza
                                        through reliable and/or publicly
                                        available sources, Big Bonanza reserves
                                        the right to adjust the deadline to such
                                        a time by which a maximum of 3 overs in
                                        the given match are bowled.
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h5>
                                  Actual match start time is after the official
                                  deadline:
                                </h5>
                                <p>
                                  Big Bonanza reserves the right to extend the
                                  deadline or abandon the contest/game based on
                                  the circumstances such as delayed toss,
                                  interruption on account of weather,
                                  non-appearance of teams, technical/equipment
                                  glitches causing delays etc.
                                </p>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </li>

                      <li>
                        Big Bonanza shall endeavor to send communications
                        through emails and/or SMS communication, about any such
                        change as is contemplated in the aforementioned
                        paragraphs to keep the User updated.
                      </li>
                      <li>
                        Teams are awarded points on the basis of the real life
                        cricketers', footballers', basketball players,
                        volleyball players, hockey players, baseball players,
                        handball players, American football players or kabaddi
                        players (as applicable) performances at the end of a
                        designated match, match or tournament of the Contest(s).
                        The Participant(s) whose Team(s) have achieved the
                        highest aggregate score(s) in the Contest(s) shall be
                        declared winners ("Winners"). In certain pre-specified
                        Contests, there may be more than one Winner and
                        distribution of prizes to such Winners will be in
                        increasing order of their Team's aggregate score at the
                        end of the designated match(s) of the Contests.
                      </li>
                      <li>
                        The Contest(s) across the Big Bonanza Services shall, in
                        addition to the Terms and Conditions, rules and
                        regulations mentioned herein, be governed by:
                        <ul>
                          <li>
                            "Fantasy Rules" available at{" "}
                            <a href="#" target="_blank">
                              How To Play -&nbsp;Fantasy Cricket
                            </a>
                            &nbsp;or{" "}
                            <a href="#" target="_blank">
                              How To Play&nbsp;Fantasy Football
                            </a>
                            &nbsp;or{" "}
                            <a href="#" target="_blank">
                              How To Play -&nbsp;Fantasy Kabaddi
                            </a>{" "}
                            or{" "}
                            <a href="#">
                              How To Play -&nbsp;Fantasy Volleyball
                            </a>{" "}
                            or{" "}
                            <a href="#" target="_blank">
                              How to Play Fantasy Hockey
                            </a>{" "}
                            or{" "}
                            <a href="#" target="_blank">
                              How to Play Fantasy Basketball
                            </a>{" "}
                            or{" "}
                            <a href="#" target="_blank">
                              How to Play-Baseball
                            </a>{" "}
                            or{" "}
                            <a href="#" target="_blank">
                              How to Play – Fantasy Handball
                            </a>
                          </li>
                          or{" "}
                          <a href="#" target="_blank">
                            How to Play – American football
                          </a>
                          <li>
                            Other rules and regulations (including rules and
                            regulation in relation to any payments made to
                            participate in the Contest(s); and all Participants
                            agree to abide by the same.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Currently, there are paid versions of the Contest(s)
                        made available on Big Bonanza platform. Users may
                        participate in the Contest(s) by paying the
                        pre-designated amount as provided on the relevant
                        Contest page. The ‘pre-designated amount’ means and
                        includes pre-determined platform fee for accessing Big
                        Bonanza services and pre-determined participant'’s
                        contribution towards prize money pool. The Participant
                        with the highest aggregate points at the end of the
                        pre-determined match shall be eligible to win a
                        pre-designated prize which is disbursed out of prize
                        money pool, as stated on the relevant Contest(s) page.
                      </li>
                      <li>
                        A Participant may create different Teams for
                        participation in Contest(s) in relation to a Sport Event
                        across the Big Bonanza Services. However, unless Big
                        Bonanza specifies otherwise in relation to any Contest
                        ("“Multiple Entry Contest"”), Participants acknowledge
                        and agree that they may enter only one Team in any
                        Contest offered in relation to a Sport Event. In case of
                        Multiple Entry Contest(s), a Participant may enter more
                        than one Team in a single Multiple Entry Contest. In
                        addition, it is expressly clarified that Big Bonanza
                        may, from time to time, restrict the maximum number of
                        Teams that may be created by a single User account (for
                        each format of the contest) or which a single User
                        account may enter in a particular Multiple Entry
                        Contest, in each case to such number as determined by
                        Big Bonanza in its sole discretion.
                      </li>
                      <li>
                        Participant shall pay a pre-designated amount for
                        participating in the contest(s) being created on the Big
                        Bonanza platform. <br />
                        In the event a Participant indicates, while entering an
                        address, that he/she is a resident of either Andhra
                        Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana
                        such Participant will not be permitted to proceed to
                        sign up for the match or contest and may not participate
                        in any paid version of the Contest(s).
                      </li>

                      <li>
                        In two members and above public contests, where all
                        participants have entered the contest with the exact
                        same teams, including the captain and vice-captain in
                        such event, contest prize money shall be equally divided
                        amongst all participants and the amount shall be
                        deposited in the Big Bonanza winning account of all
                        participants and the remaining amount shall be credited
                        in the Discount Bonus account of such participants. In
                        the event a user is found to be violating this policy,
                        the Company reserves the right to initiate appropriate
                        action against such users as it deems fit, which shall
                        also include forfeiture of the Discount Bonus Amount.
                      </li>
                      <li>
                        In Starting Lineup Feature : Purpose of indicating a
                        player's inclusion in final starting lineup is only to
                        provide information and assist a user in selecting
                        his/her team on Big Bonanza. While indicating a Player's
                        inclusion in starting lineup is given by Big Bonanza on
                        the basis of information/data received through feed
                        providers, publicly available information. Users are
                        advised to do a thorough research of their own from
                        official sources and/or other available sources of
                        information. Big Bonanza, shall not take any liability,
                        if a player earlier indicated as 'Playing' does not play
                        or start for any reason whatsoever.
                      </li>

                      <li>
                        Substitutes on the field will not be awarded points for
                        any contribution they make. However, 'Concussion or
                        COVID-19 or X Factor or Impact Player Substitutes' will
                        ONLY be awarded points four (4) points for making an
                        appearance in a match in addition to points for any
                        contribution they make as per the Fantasy Points System
                        (w.e.f Oct 9 2022).
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Contest Formats</h3>
                  <div>
                    <ul>
                      <li>
                        Currently, two formats of contests are made available on
                        Big Bonanza platform (1) Public Contests, where Users
                        can participate in a Contest with other Users without
                        any restriction on participation and (2) Private
                        Contests, where Users can invite specific Users into a
                        Contest and restrict participation to such invited
                        Users. A user can enter into a maximum of 2,000 contests
                        (including both Public and Private contests) for any
                        cricket match and a maximum of 500 contests for any
                        other non-cricket match on Big Bonanza. Any
                        participation in contests over the set limit shall be
                        automatically prohibited. All rules applicable to
                        Contests as set out herein shall be applicable to both
                        formats of Contests.
                      </li>
                      <li>
                        <strong>Public contest</strong>
                        <ul>
                          <li>
                            In the Public contest format of the Contest(s), Big
                            Bonanza may make available the Contest(s) comprising
                            of 2 -– 100 Participants or any other pre-designated
                            number of Participants.
                          </li>
                          <li>
                            Big Bonanza may create this format of the Contest(s)
                            as a paid format and the Winner will be determinable
                            at the end of the match as per rule of the contests.
                          </li>
                          <li>
                            The number of Participants required to make the
                            Contest(s) operational will be pre-specified and
                            once the number of Participants in such Contest(s)
                            equals the pre-specified number required for that
                            Contest(s), such Contest(s) shall be operational. In
                            case the number of Participants is less than the
                            pre-specified number at the time of commencement of
                            the match, such Contest(s) will not be operational
                            and the pre-designated amount paid by each
                            Participant shall be returned to the account of such
                            User without any charge or deduction.
                          </li>
                          <li>
                            In certain Contests across the Big Bonanza Services,
                            designated as “Guaranteed contests”, the Contest(s)
                            shall become operational only when a minimum of two
                            users join a Guaranteed Contest. The pre-specified
                            number of winners to be declared in such Contest(s),
                            even if all available Participant slots (as
                            pre-specified in relation to the Contest(s)) remain
                            unfilled. It is clarified that notwithstanding the
                            activation of such Contest(s), Participants can
                            continue to join such Contest(s) till either (i) all
                            available Participant slots of such Contest(s) are
                            filled or (ii) the match to which the Contest (s)
                            relates commences, whichever is earlier. In the
                            event of shortfall in the number of participants
                            joining the Guaranteed Contest, Big Bonanza shall
                            continue with such contests and the short fall in
                            the prize pool shall be borne by Big Bonanza.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Private contest</strong>
                        <ul>
                          <li>
                            In the Private contest format of the Contest(s), Big
                            Bonanza enables Users to create a contest ("“Private
                            contest"”) and invite other users, whether existing
                            Users or otherwise, ("“Invited User"”) to create
                            Teams and participate in the Contest(s). Users may
                            create a Private contest to consist of a
                            pre-specified number of Participants, that is,
                            consisting of either 2-20000 Participants. The User
                            creating the Private contest shall pay the
                            pre-designated amount for such Private contest and
                            thereby join that Private contest and shall supply a
                            name for the Private contest and be provided with a
                            unique identification code ("contest Code") (which
                            will be issued to the account of such User). The
                            User agrees and understands that once the Private
                            contest is created no change shall be permitted in
                            the terms or constitution of the Private contest,
                            except for a change in the name of the contest. The
                            User creating the Private contest shall provide Big
                            Bonanza with the email address or Facebook account
                            username of Invited Users to enable Big Bonanza to
                            send a message or mail inviting such Invited User to
                            register with Big Bonanza (if necessary) and
                            participate in the Private contest in relation to
                            which the invite has been issued.
                          </li>
                          <li>
                            In order to participate in the Private contest, an
                            Invited User shall input the contest Code associated
                            with the Private contest and pay the pre-designated
                            amount for the Private contest. Once the number of
                            Participants in a Private contest equals the number
                            of pre-specified Participants for that Private
                            contest, the Private contest shall be rendered
                            operative and no other Invited Users or Users shall
                            be permitted to participate in the Private contest.
                            In the event that any Private contest does not
                            contain the pre-specified number of Participants for
                            that Private contest within 1 hour prior to the
                            commencement of the match/Contest, the Platform will
                            initiate an automatic refund of the amount
                            deposited. Such refund shall be processed after the
                            expiry of the deadline for filling of participants
                            for such Private Contest.
                          </li>
                          <li>
                            It is clarified that the participation of Invited
                            Users in any Private contest is subject to the
                            pre-specified number of Participants for that
                            Private contest, and Big Bonanza shall not be liable
                            to any person for the inability of any Invited User
                            to participate in any Private contest due to any
                            cause whatsoever, including without limitation due
                            to a hardware or technical malfunction or lack of
                            eligibility of such Invited User to participate in
                            the Contest(s).
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Legality of Game of Skill</h3>
                  <div>
                    <ul>
                      <li>
                        Games of skill are legal, as they are excluded from the
                        ambit of Indian gambling legislations including, the
                        Public Gambling Act of 1867.The Indian Supreme Court in
                        the cases of State of Andhra Pradesh v. K Satyanarayana
                        (AIR 1968 SC 825) and KR Lakshmanan v. State of Tamil
                        Nadu (AIR 1996 SC 1153) has held that a game in which
                        success depends predominantly upon the superior
                        knowledge, training, attention, experience and
                        adroitness of the player shall be classNameified as a
                        game of skill.
                      </li>
                      <li>
                        The Contest (s) described above (across the Big Bonanza
                        Services) are games of skill as success of Participants
                        depends primarily on their superior knowledge of the
                        games of cricket and/or football and/or basketball
                        and/or hockey and/or handball and/ or volleyball and/or
                        kabaddi and/or American football and/or baseball
                        statistics, knowledge of players' relative form,
                        players' performance in a particular territory,
                        conditions and/or format (such as ODIs, test cricket and
                        Twenty20 in the cricket fantasy game), attention and
                        dedication towards the Contest(s) and adroitness in
                        playing the Contest(s). The Contest(s) also requires
                        Participants to field well-balanced sides with limited
                        resources and make substitutions at appropriate times to
                        gain the maximum points.
                      </li>
                      <li>
                        By participating in this Contest(s), each Participant
                        acknowledges and agrees that he/she is participating in
                        a game of skill.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Eligibility</h3>
                  <div>
                    <ul>
                      <li>
                        The Contest(s) are open only to persons above the age of
                        18 years.
                      </li>
                      <li>
                        The Contest(s) are open only to persons, currently
                        residing in India.
                      </li>
                      <li>
                        Big Bonanza may, in accordance with the laws prevailing
                        in certain Indian states, bar individuals residing in
                        those states from participating in the Contest(s).
                        Currently, individuals residing in the Indian states of
                        Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim or
                        Telangana may not participate in the paid version of the
                        Contest as the laws of these states are unclear/ bar
                        persons from participating in games of skill where
                        participants are required to pay to enter.
                      </li>
                      <li>
                        Persons who wish to participate must have a valid email
                        address.
                      </li>
                      <li>
                        Big Bonanza may on receipt of information bar a person
                        from participation and/or withdrawing winning amounts if
                        such person is found to be one with insider knowledge of
                        participating teams in any given contests/match,
                        organizing boards, leagues etc.
                      </li>
                      <li>
                        Only those Participants who have successfully registered
                        on the Big Bonanza as well as registered prior to each
                        match in accordance with the procedure outlined above
                        shall be eligible to participate in the Contest and win
                        prizes.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Payment Terms</h3>
                  <div>
                    <ul>
                      <li>
                        Users by participating in a Contest(s) hereby authorize
                        Big Bonanza to appoint a third party/ Trustee/Escrow
                        Agent to manage users funds on users behalf. In respect
                        of any transactions entered into on the Big Bonanza
                        platform, including making a payment to participate in
                        the paid versions of Contest(s), Users agree to be bound
                        by the following payment terms:
                        <ul>
                          <li>
                            The payment of pre-designated amount Users make to
                            participate in the Contest(s) is inclusive of the
                            pre-designated Platform Fee for access to the Big
                            Bonanza Services charged by Big Bonanza and
                            pre-determined participant’s contribution towards
                            prize money pool.
                          </li>
                          <li>
                            Subject to these Terms and Conditions, all amounts
                            collected from the User are held in a separate
                            non-interest earning bank Accounts. The said
                            accounts are operated by a third party appointed by
                            Big Bonanza in accordance with Clause 10 of these
                            Terms and Conditions. From these bank accounts, the
                            payouts can be made to (a) Users (towards their
                            withdrawals), (b) Big Bonanza (towards its Platform
                            Fees) and to (c) Government (towards GST payable)
                            and to (d) Government (towards TDS on Net Winnings).{" "}
                          </li>
                          <li>
                            The Big Bonanza reserves the right to charge a
                            Platform Fee, , prior to a User's joining of such
                            Contest. The Platform Fee will be debited from the
                            User’s account balance.
                          </li>
                          <li>
                            From 01 Oct 2023, 28% GST is applicable on amount
                            deposited by the User in its Amount Unutilised
                            Account with Big Bonanza platform. User can view the
                            GST paid on each deposit through invoices on ‘My
                            Transaction’ page.
                          </li>
                          <li>
                            The User may participate in a Contest wherein the
                            User has to contribute a pre-specified contribution
                            towards the Prize Money Pool of such Contest, which
                            will be passed on to the Winner(s) of the Contest
                            after the completion of the Contest as per the terms
                            and conditions of such Contest. It is clarified that
                            Big Bonanza has no right or interest in this Prize
                            Money Pool, and only acts as an intermediary engaged
                            in collecting and distributing the Prize Money Pool
                            in accordance with the Contest terms and conditions.
                            The amount to be paid-in by the User towards the
                            Prize Money Pool would also be debited from the
                            User’s account balance maintained with the Trustee.
                          </li>
                          <li>
                            Any user availing Big Bonanza services are provided
                            with two categories of accounts for the processing
                            and reconciliation of payments: (i) 'Amount
                            Unutilised' Account, (ii) Winnings Account. It is
                            clarified that in no instance the transfer of any
                            amounts in the User's accounts to any other category
                            of account held by the user or any third party
                            account, including a bank account held by a third
                            party:
                            <ul>
                              <li>
                                User's winnings in any Contest will reflect as
                                credits to the User's Winnings Account.
                              </li>
                              <li>
                                User’s remitting the amount through the
                                designated payment gateway shall be credited to
                                User’s ‘Amount Unutilised Account’.
                              </li>
                            </ul>
                          </li>
                          <li>
                            Each time a User participates in any contest on Big
                            Bonanza platform, the pre-designated amount shall be
                            debited from the User’s account. In debiting amounts
                            from the User’s accounts towards the pre-designated
                            amount of such user shall be debited from the User’s
                            ‘Amount Unutilised Account and thereafter, any
                            remaining amount of participation fee shall be
                            debited from the User’s Winning Account.
                          </li>
                          <li>
                            In case there is any amount remaining to be paid by
                            the User in relation to such User’s participation in
                            any match(s) or Contest(s), the User will be taken
                            to the designated payment gateway to give effect to
                            such payment. At the time of making deposit, the
                            User agrees that Big Bonanza will collect applicable
                            Government Taxes (such as GST @ 28%) in addition to
                            the amount to be deposited by the User in its Amount
                            Unutilised account. This will be duly reflected by
                            Big Bonanza to the User at the time of making
                            payment. In case any amount added by the User
                            exclusive of applicable Government Taxes (such as
                            GST @ 28%) through such payment gateway exceeds the
                            remaining amount of the pre-designated amount, the
                            amount in excess shall be transferred to the User’s
                            ‘Amount Unutilised’ Account and will be available
                            for use in participation in any match(s) or
                            Contest(s). If there is any request for withdrawal
                            of amount deposited in ‘Amount Unutilised’ Account,
                            such refund request shall be processed net of GST
                            i.e. applicable 28% GST paid on amount deposited in
                            ‘Amount Unutilised’ Account shall not be eligible
                            for refund as per applicable government laws.
                          </li>
                          <li>
                            Debits from the ‘Amount Unutilised’ Account for the
                            purpose of enabling a user’s participation in a
                            Contest shall be made in order of the date of credit
                            of amounts in the ‘Amount Unutilised’ Account, and
                            accordingly amounts credited into ‘Amount
                            Unutilised’ Account earlier in time shall be debited
                            first.
                          </li>

                          <li>
                            Withdrawal of any amount standing to the User's
                            credit in the Winnings Account may be made by way of
                            a request to Big Bonanza. Big Bonanza shall effect
                            an online transfer to the User's bank account on
                            record with Big Bonanza within a commercially
                            reasonable period of time. Such transfer will
                            reflect as a debit to the User's Winnings Account.
                            Big Bonanza shall not charge any processing fee for
                            the online transfer of such amount from the Winnings
                            Account to the User's bank account on record with
                            Big Bonanza. Users are requested to note that they
                            will be required to provide valid photo
                            identification, Pan Card and address proof documents
                            for proof of identity and address in order for Big
                            Bonanza to process the withdrawal request. The name
                            mentioned on the User's photo identification
                            document should correspond with the name provided by
                            the User at the time of registration on Big Bonanza,
                            as well as the name and address existing in the
                            records of the User's bank account as provided to
                            Big Bonanza. In the event that no bank account has
                            been registered by the User against such User's
                            account with Big Bonanza, or the User has not
                            verified his/her User account with Big Bonanza, to
                            Big Bonanza's satisfaction and in accordance with
                            these Terms and Conditions, and in case the User
                            fails to register a bank account with his/her User
                            Account and/or to verify his/her User Account, Big
                            Bonanza shall not transfer any Winning amounts to
                            the User.
                          </li>

                          <li>
                            Users agree that once they confirm a transaction on
                            Big Bonanza, they shall be bound by and make payment
                            for that transaction.
                          </li>
                          <li>
                            The User acknowledges that subject to time taken for
                            bank reconciliations and such other external
                            dependencies that Big Bonanza has on third parties,
                            any transactions on Big Bonanza Platform may take up
                            to 24 hours to be processed. Any amount paid or
                            transferred into the User's 'Amount Unutilised'
                            Account or Winnings Account may take up to 24 hours
                            to reflect in the User's 'Amount Unutilised' Account
                            or Winnings Account balance. Similarly, the
                            utilization of any discount points or discount bonus
                            or money debited from 'Amount Unutilised' Account or
                            Winnings Account may take up to 24 hours to reflect
                            in the User's 'Amount Unutilised' Account or
                            Winnings Account balance. Users agree not to raise
                            any complaint or claim against Big Bonanza in
                            respect of any delay, including any lost opportunity
                            to join any Contest or match due to delay in
                            crediting of transaction amount into any of the
                            User's accounts
                            <br />
                            A transaction, once confirmed, is final and no
                            cancellation is permissible.
                            <br />
                            Big Bonanza may, in certain exceptional
                            circumstances and at its sole and absolute
                            discretion, refund the amount to the User after
                            deducting applicable cancellation charges and taxes.
                            At the time of the transaction, Users may also be
                            required to take note of certain additional terms
                            and conditions and such additional terms and
                            conditions shall also govern the transaction. To the
                            extent that the additional terms and conditions
                            contain any clause that is conflicting with the
                            present terms and conditions, the additional terms
                            and conditions shall prevail.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Discounts Offered</h3>
                  <div>
                    <div>
                      In order to conduct promotional activities and provide
                      incentives, Big Bonanza may gratuitously issue discounts
                      to the User which may be utilised for any Contest(s) and
                      no User shall be permitted to transfer or request the
                      transfer of any amount of discounts to any other account.
                      The usage of any discounts issued as a promotional measure
                      or to provide incentives shall be subject to the
                      limitations and restrictions, including without
                      limitation, restrictions as to time within which discounts
                      must be used, as applied by Big Bonanza and notified to
                      the User at the time of issue of such amount. The issue of
                      any discounts to the Users is subject to the sole
                      discretion of Big Bonanza and cannot be demanded by any
                      User as a matter of right. The issue of any discount by
                      Deam11 on any day shall not entitle the User to demand the
                      issuance of such discount at any subsequent period in time
                      nor create an expectation of recurring issue of such
                      discount by Big Bonanza to such User. The discount granted
                      to the User may be used by such User for the purpose of
                      setting off against the contribution to prize pool in any
                      Contest, in accordance with these Terms and Conditions.
                      The discounts shall not be withdraw-able or transferrable
                      to any other account of the User, including the bank
                      account of such User, or of any other User or person,
                      other that as part of the winnings of a User in any
                      Contest(s). In case the User terminates his/her account
                      with Big Bonanza or such account if terminated by Big
                      Bonanza, all discounts granted to the user shall return to
                      Big Bonanza and the User shall not have any right or
                      interest on such discount.
                    </div>
                    <div>
                      Big Bonanza currently grants discounts of three types
                    </div>
                    <ul>
                      <li>
                        Discount Bonus:
                        <ul>
                          <li>
                            All Discount Bonus awarded in the User account shall
                            be valid for a period of 14 days from the date of
                            award. The Discount Bonus shall lapse at the end of
                            14 days. The Discount Bonus amount shall reflect in
                            a separate “Discount Bonus” account.
                          </li>
                          <li>
                            Discount bonus is non-transferable and cannot be
                            withdrawn.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Discount Points:
                        <ul>
                          <li>
                            All Discount Points shall be valid for a period of
                            three (03) months from the date of award of points.
                          </li>
                          <li>
                            Discount Points are non-transferable, cannot be
                            withdrawn.
                          </li>
                          <li>
                            Discount points are awarded on successful deposit in
                            the Amount unutilised Account. In the event of any
                            withdrawal of the deposited amount in Amount
                            unutilised Account, the Discount Points given to the
                            user for such deposit amount shall also be captured
                            and the user will not be able to utilise such
                            captured Discount Points.
                          </li>
                          <li>
                            Discount Points can be utilised for joining a
                            contest as a discount for a maximum of 22% of the
                            contest entry amount (less any other discounts
                            available with the user).
                          </li>
                          <li>
                            In the event of a match abandonment or cancellation,
                            the Discount Points used by the user in such contest
                            shall be returned to the users Discount Points
                            Account.{" "}
                          </li>
                        </ul>
                      </li>
                      <li>
                        Discount Coupons
                        <ul>
                          <li>
                            Discount coupons shall be given by Big Bonanza at
                            its sole discretion. Each discount coupon shall be
                            governed by the specific terms and conditions as
                            notified by Big Bonanza at the time of grant of such
                            discount coupons.{" "}
                          </li>
                          <li>
                            Each discount coupon shall have its own validity and
                            specific terms and conditions.{" "}
                          </li>
                          <li>
                            In case of any conflict between these terms and the
                            terms specific to the discount coupon, the terms
                            specific to the discount coupon shall prevail.{" "}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Tabulation of fantasy points</h3>
                  <div>
                    <div>
                      Big Bonanza may obtain the score feed and other
                      information required for the computation and tabulation of
                      fantasy points from third party service provider(s) and/or
                      official website of the match organiser. In the rare event
                      that any error in the computation or tabulation of fantasy
                      points, selection of winners, abandonment of a match etc.,
                      as a result of inaccuracies in or incompleteness of the
                      feed provided by the third party service provider and/or
                      official website of the match organiser comes to its
                      attention, Big Bonanza shall use best efforts to rectify
                      such error prior to the distribution of prizes. However,
                      Big Bonanza hereby clarifies that it relies on the
                      accuracy and completeness of such third party
                      score/statistic feeds and does not itself warrant or make
                      any representations concerning the accuracy thereof and,
                      in any event, shall take no responsibility for
                      inaccuracies in computation and tabulation of fantasy
                      points or the selection of winners as a result of any
                      inaccurate or incomplete scores/statistics received from
                      such third party service provider. Users and Participants
                      agree not to make any claim or raise any complaint against
                      Big Bonanza in this respect.
                    </div>
                  </div>
                </li>
                <li>
                  <h3>
                    Selection and Verification of Winners and Conditions
                    relating to the Prizes
                  </h3>
                  <div>
                    <ul>
                      <li>
                        <strong>Selection of Winners</strong> <br />
                        Winners will be decided on the basis of the scores of
                        the Teams in a designated match (which may last anywhere
                        between one day and an entire tournament) of the
                        Contest(s). The Participant(s) owning the Team(s) with
                        the highest aggregate score in a particular match shall
                        be declared the Winner(s). In certain pre-specified
                        Contests, Big Bonanza may declare more than one Winner
                        and distribute prizes to such Winners in increasing
                        order of their Team's aggregate score at the end of the
                        designated match of the Contest. The contemplated number
                        of Winners and the prize due to each Winner in such
                        Contest shall be as specified on the Contest page prior
                        to the commencement of the Contest. <br />
                        Participants creating Teams on behalf of any other
                        Participant or person shall be disqualified. <br />
                        In the event of a tie, the winning Participants shall be
                        declared Winners and the prize shall be equally divided
                        among such Participants. <br />
                        Big Bonanza shall not be liable to pay any prize if it
                        is discovered that the Winner(s) have not abided by
                        these Terms and Conditions, and other rules and
                        regulations in relation to the use of the Big Bonanza,
                        Contest, "Fantasy Rules", etc.
                      </li>
                      <li>
                        <strong>Contacting Winners</strong> <br />
                        Winners shall be contacted by Big Bonanza or the third
                        party conducting the Contest on the e-mail address
                        provided at the time of registration. The verification
                        process and the documents required for the collection of
                        prize shall be detailed to the Winners at this stage. As
                        a general practice, winners will be required to provide
                        following documents:
                        <ul>
                          <li>Photocopy of the User's PAN card;</li>
                          <li>
                            Photocopy of a government-issued residence proof;
                          </li>
                          <li>
                            User's bank account details and proof of the same.
                          </li>
                        </ul>
                        Big Bonanza shall not permit a Winner to withdraw
                        his/her prize(s)/accumulated winnings unless the
                        above-mentioned documents have been received and
                        verified within the time-period stipulated by Big
                        Bonanza. The User represents and warrants that the
                        documents provided in the course of the verification
                        process are true copies of the original documents to
                        which they relate. <br />
                        <br />
                        Participants are required to provide proper and complete
                        details at the time of registration. Big Bonanza shall
                        not be responsible for communications errors,
                        commissions or omissions including those of the
                        Participants due to which the results may not be
                        communicated to the Winner. <br />
                        <br />
                        The list of Winners shall be posted on a separate
                        web-page on the Big Bonanza. The winners will also be
                        intimated by e-mail. <br />
                        <br />
                        In the event that a Participant has been declared a
                        Winner on the abovementioned web-page but has not
                        received any communication from Big Bonanza, such
                        Participant may contact Big Bonanza within the time
                        specified on the webpage.
                      </li>
                      <li>
                        <strong>Verification process</strong> <br />
                        Only those Winners who successfully complete the
                        verification process and provide the required documents
                        within the time limit specified by Big Bonanza shall be
                        permitted to withdraw/receive their accumulated winnings
                        (or any part thereof). Big Bonanza shall not entertain
                        any claims or requests for extension of time for
                        submission of documents. <br />
                        Big Bonanza shall scrutinize all documents submitted and
                        may, at its sole and absolute discretion, disqualify any
                        Winner from withdrawing his accumulated winnings (or any
                        part thereof) on the following reasons:
                        <ul>
                          <li>
                            Determination by Big Bonanza that any document or
                            information submitted by the Participant is
                            incorrect, misleading, false, fabricated, incomplete
                            or illegible; or
                          </li>
                          <li>
                            Participant does not fulfill the Eligibility
                            Criteria specified in Clause 10 above; or
                          </li>
                          <li>Any other match.</li>
                        </ul>
                      </li>
                      <li>
                        <strong>Taxes Payable</strong> <br />
                        <ul>
                          <li>
                            All prizes shall be subject to deduction of tax
                            (“TDS”) as per the Income Tax Act 1961. With effect
                            from 1st April 2023, TDS of 30% shall be deducted on
                            Net Winnings (NW) on each withdrawal request placed
                            by the user.{" "}
                          </li>
                          <li>
                            Net Winnings shall be calculated as follows: NW =
                            (A+D)-(B+C+E). Where : A= Total withdrawal
                            (including current withdrawal amount), B= Total
                            Deposit, C= Opening Balance at the beginning of
                            financial year, D= Closing balance of the user
                            account at the end of financial year, E= Amount on
                            which TDS was deducted.
                          </li>
                        </ul>
                        <div>
                          In the event, a user does not withdraw any of his
                          winnings during a financial year and has Net Winning
                          balance as at 31 March of each year, then TDS of 30%
                          shall be deducted on such Net Winnings balance from
                          the user’s Winning Account as on 31 March. In case of
                          any revisions by the Government of India to the
                          aforementioned rate or the definition of Net Winnings
                          in the future, TDS will be deducted by Big Bonanza in
                          accordance with the then current prescribed TDS rate
                          and the revised definition of Net Winnings. Winners
                          will be provided TDS certificates in respect of such
                          tax deductions. The Winners shall be responsible for
                          payment of any other applicable taxes, including but
                          not limited to, income tax, gift tax, etc. in respect
                          of the Net Winnings. Big Bonanza shall not in any
                          manner be responsible for users individual tax
                          matters.
                        </div>
                      </li>
                      <li>
                        <strong>Miscellaneous</strong> <br />
                        The decision of Big Bonanza with respect to the awarding
                        of prizes shall be final, binding and non-contestable.{" "}
                        <br />
                        <br />
                        Participants playing the paid formats of the Contest(s)
                        confirm that they are not residents of any of the
                        following Indian states - Andhra Pradesh, Assam,
                        Nagaland, Odisha, Sikkim or Telangana. If it is found
                        that a Participant playing the paid formats of the
                        Contest(s) is a resident of any of the abovementioned
                        states, Big Bonanza shall disqualify such Participant
                        and forfeit any prize won by such Participant. Further
                        Big Bonanza may, at its sole and absolute discretion,
                        suspend or terminate such Participant's account with Big
                        Bonanza. Any amount remaining unused in the User's
                        Amount Unutilised Account or Winnings Account ( subject
                        to deduction of applicable TDS with effect from 1st
                        April 2023 as applicable ) on the date of deactivation
                        or deletion shall be reimbursed to the User by an online
                        transfer to the User's bank account on record with Big
                        Bonanza, subject to the processing fee (if any)
                        applicable on such transfers as set out herein. <br />
                        <br />
                        If it is found that a Participant playing the paid
                        formats of the Contest(s) is under the age of eighteen
                        (18), Big Bonanza shall be entitled, at its sole and
                        absolute discretion, to disqualify such Participant and
                        forfeit his/her prize. Further, Big Bonanza may, at its
                        sole and absolute discretion, suspend or terminate such
                        Participant's account. <br />
                        <br />
                        To the extent permitted by law, Big Bonanza makes no
                        representations or warranties as to the quality,
                        suitability or merchantability of any prizes and shall
                        not be liable in respect of the same. <br />
                        <br />
                        Big Bonanza may, at its sole and absolute discretion,
                        vary or modify the prizes being offered to winners.
                        Participants shall not raise any claim against Big
                        Bonanza or question its right to modify such prizes
                        being offered, prior to closure of the Contest.
                        <br />
                        <br />
                        Big Bonanza will not bear any responsibility for the
                        transportation or packaging of prizes to the respective
                        winners. Big Bonanza shall not be held liable for any
                        loss or damage caused to any prizes at the time of such
                        transportation.
                        <br />
                        <br />
                        The Winners shall bear the shipping, courier or any
                        other delivery cost in respect of the prizes.
                        <br />
                        <br />
                        The Winners shall bear all transaction charges levied
                        for delivery of cash prizes.
                        <br />
                        <br />
                        All prizes are non-transferable and non-refundable.
                        Prizes cannot be exchanged / redeemed for cash or kind.
                        No cash claims can be made in lieu of prizes in kind.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Publicity</h3>
                  <div>
                    <div>
                      Acceptance of a prize by the Winner constitutes permission
                      for Big Bonanza, and its affiliates to use the Winner's
                      name, likeness, voice and comments for advertising and
                      promotional purposes in any media worldwide for purposes
                      of advertising and trade without any further permissions
                      or consents and / or additional compensation whatsoever.{" "}
                      <br />
                      The Winners further undertake that they will be available
                      for promotional purposes as planned and desired by Big
                      Bonanza without any charge. The exact dates remain the
                      sole discretion of Big Bonanza. Promotional activities may
                      include but not be limited to press events, internal
                      meetings and ceremonies/functions.
                    </div>
                  </div>
                </li>
                <li>
                  <h3>General Conditions</h3>
                  <div>
                    <div>
                      If it comes to the notice of Big Bonanza that any
                      governmental, statutory or regulatory compliances or
                      approvals are required for conducting any Contest(s) or if
                      it comes to the notice of Big Bonanza that conduct of any
                      such Contest(s) is prohibited, then Big Bonanza shall
                      withdraw and / or cancel such Contest(s) without prior
                      notice to any Participants or winners of any Contest(s).
                      Users agree not to make any claim in respect of such
                      cancellation or withdrawal of the Contest or Contest it in
                      any manner. <br />
                      <br />
                      Employees are not be eligible to participate in any Public
                      Contest(s).
                    </div>
                  </div>
                </li>
                <li>
                  <h3>Dispute and Dispute Resolution</h3>
                  <div>
                    <ul>
                      <li>
                        The courts of competent jurisdiction at Mumbai shall
                        have exclusive jurisdiction to determine any and all
                        disputes arising out of, or in connection with, the Big
                        Bonanza Services provided by Big Bonanza (including the
                        Contest(s)), the construction, validity, interpretation
                        and enforceability of these Terms and Conditions, or the
                        rights and obligations of the User(s) (including
                        Participants) or Big Bonanza, as well as the exclusive
                        jurisdiction to grant interim or preliminary relief in
                        case of any dispute referred to arbitration as given
                        below. All such issues and questions shall be governed
                        and construed in accordance with the laws of the
                        Republic of India.
                      </li>
                      <li>
                        In the event of any legal dispute (which may be a legal
                        issue or question) which may arise, the party raising
                        the dispute shall provide a written notification
                        ("Notification") to the other party. On receipt of
                        Notification, the parties shall first try to resolve the
                        dispute through discussions. In the event that the
                        parties are unable to resolve the dispute within fifteen
                        (15) days of receipt of Notification, the dispute shall
                        be settled by arbitration.
                      </li>
                      <li>
                        The place of arbitration shall be Mumbai, India. All
                        arbitration proceedings shall be conducted in English
                        and in accordance with the provisions of the Arbitration
                        and Conciliation Act, 1996, as amended from time to
                        time.
                      </li>
                      <li>
                        The arbitration award will be final and binding on the
                        Parties, and each Party will bear its own costs of
                        arbitration and equally share the fees of the arbitrator
                        unless the arbitral tribunal decides otherwise. The
                        arbitrator shall be entitled to pass interim orders and
                        awards, including the orders for specific performance
                        and such orders would be enforceable in competent
                        courts. The arbitrator shall give a reasoned award.
                      </li>
                      <li>
                        Nothing contained in these Terms and Conditions shall
                        prevent Big Bonanza from seeking and obtaining interim
                        or permanent equitable or injunctive relief, or any
                        other relief available to safeguard Big Bonanza's
                        interest prior to, during or following the filing of
                        arbitration proceedings or pending the execution of a
                        decision or award in connection with any arbitration
                        proceedings from any court having jurisdiction to grant
                        the same. The pursuit of equitable or injunctive relief
                        shall not constitute a waiver on the part of Big Bonanza
                        to pursue any remedy for monetary damages through the
                        arbitration described herein.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Release and Limitations of Liability</h3>
                  <div>
                    <ul>
                      <li>
                        Users shall access the Big Bonanza Services provided on
                        Big Bonanza voluntarily and at their own risk. Big
                        Bonanza shall, under no circumstances be held
                        responsible or liable on account of any loss or damage
                        sustained (including but not limited to any accident,
                        injury, death, loss of property) by Users or any other
                        person or entity during the course of access to the Big
                        Bonanza Services (including participation in the
                        Contest(s)) or as a result of acceptance of any prize.
                      </li>
                      <li>
                        By entering the contests and accessing the Big Bonanza
                        Services provided therein, Users hereby release from and
                        agree to indemnify Big Bonanza, and/ or any of its
                        directors, employees, partners, associates and
                        licensors, from and against all liability, cost, loss or
                        expense arising out their access to the Big Bonanza
                        Services including (but not limited to) personal injury
                        and damage to property and whether direct, indirect,
                        consequential, foreseeable, due to some negligent act or
                        omission on their part, or otherwise.
                      </li>
                      <li>
                        Big Bonanza accepts no liability, whether jointly or
                        severally, for any errors or omissions, whether on
                        behalf of itself or third parties in relation to the
                        prizes.
                      </li>
                      <li>
                        Users shall be solely responsible for any consequences
                        which may arise due to their access of Big Bonanza
                        Services by conducting an illegal act or due to
                        non-conformity with these Terms and Conditions and other
                        rules and regulations in relation to Big Bonanza
                        Services, including provision of incorrect address or
                        other personal details. Users also undertake to
                        indemnify Big Bonanza and their respective officers,
                        directors, employees and agents on the happening of such
                        an event (including without limitation cost of attorney,
                        legal charges etc.) on full indemnity basis for any
                        loss/damage suffered by Big Bonanza on account of such
                        act on the part of the Users.
                      </li>
                      <li>
                        Users shall indemnify, defend, and hold Big Bonanza
                        harmless from any third party/entity/organization claims
                        arising from or related to such User's engagement with
                        the Big Bonanza or participation in any Contest. In no
                        event shall Big Bonanza be liable to any User for acts
                        or omissions arising out of or related to User's
                        engagement with the Big Bonanza or his/her participation
                        in any Contest(s).
                      </li>
                      <li>
                        In consideration of Big Bonanza allowing Users to access
                        the Big Bonanza Services, to the maximum extent
                        permitted by law, the Users waive and release each and
                        every right or claim, all actions, causes of actions
                        (present or future) each of them has or may have against
                        Big Bonanza, its respective agents, directors, officers,
                        business associates, group companies, sponsors,
                        employees, or representatives for all and any injuries,
                        accidents, or mishaps (whether known or unknown) or
                        (whether anticipated or unanticipated) arising out of
                        the provision of Big Bonanza Services or related to the
                        Contests or the prizes of the Contests.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Disclaimers</h3>
                  <div>
                    <ul>
                      <li>
                        To the extent permitted under law, neither Big Bonanza
                        nor its parent/holding company, subsidiaries,
                        affiliates, directors, officers, professional advisors,
                        employees shall be responsible for the deletion, the
                        failure to store, the mis-delivery, or the untimely
                        delivery of any information or material.
                      </li>
                      <li>
                        To the extent permitted under law, Big Bonanza shall not
                        be responsible for any harm resulting from downloading
                        or accessing any information or material, the quality of
                        servers, games, products, Big Bonanza services or sites,
                        cancellation of competition and prizes. Big Bonanza
                        disclaims any responsibility for, and if a User pays for
                        access to one of Big Bonanza's Services the User will
                        not be entitled to a refund as a result of, any
                        inaccessibility that is caused by Big Bonanza's
                        maintenance on the servers or the technology that
                        underlies our sites, failures of Big Bonanza's service
                        providers (including telecommunications, hosting, and
                        power providers), computer viruses, natural disasters or
                        other destruction or damage of our facilities, acts of
                        nature, war, civil disturbance, or any other cause
                        beyond our reasonable control. In addition, Big Bonanza
                        does not provide any warranty as to the content on the
                        Big Bonanza(s). Big Bonanza(s) content is distributed on
                        an "as is, as available" basis.
                      </li>
                      <li>
                        Any material accessed, downloaded or otherwise obtained
                        through Big Bonanza is done at the User's discretion,
                        competence, acceptance and risk, and the User will be
                        solely responsible for any potential damage to User's
                        computer system or loss of data that results from a
                        User's download of any such material.
                      </li>
                      <li>
                        Big Bonanza shall make best endeavours to ensure that
                        the Big Bonanza(s) is error-free and secure, however,
                        neither Big Bonanza nor any of its partners, licensors
                        or associates makes any warranty that:
                        <ul>
                          <li>
                            the Big Bonanza(s) will meet Users' requirements,
                          </li>
                          <li>
                            Big Bonanza(s) will be uninterrupted, timely,
                            secure, or error free
                          </li>
                          <li>
                            the results that may be obtained from the use of Big
                            Bonanza(s) will be accurate or reliable; and
                          </li>
                          <li>
                            the quality of any products, Big Bonanza Services,
                            information, or other material that Users purchase
                            or obtain through Big Bonanzacom(s) will meet Users'
                            expectations.
                          </li>
                        </ul>
                      </li>
                      <li>
                        In case Big Bonanza discovers any error, including any
                        error in the determination of Winners or in the transfer
                        of amounts to a User's account, Big Bonanza reserves the
                        right (exercisable at its discretion) to rectify the
                        error in such manner as it deems fit, including through
                        a set-off of the erroneous payment from amounts due to
                        the User or deduction from the User's account of the
                        amount of erroneous payment. In case of exercise of
                        remedies in accordance with this clause, Big Bonanza
                        agrees to notify the User of the error and of the
                        exercise of the remedy(ies) to rectify the same.
                      </li>
                      <li>
                        To the extent permitted under law, neither Big Bonanza
                        nor its partners, licensors or associates shall be
                        liable for any direct, indirect, incidental, special, or
                        consequential damages arising out of the use of or
                        inability to use our sites, even if we have been advised
                        of the possibility of such damages.
                      </li>
                      <li>
                        Any Big Bonanza Services, events or Contest(s) being
                        hosted or provided, or intended to be hosted on Big
                        Bonanza platform and requiring specific permission or
                        authority from any statutory authority or any state or
                        the central government, or the board of directors shall
                        be deemed cancelled or terminated, if such permission or
                        authority is either not obtained or denied either before
                        or after the availability of the relevant Big Bonanza
                        Services, events or Contest(s) are hosted or provided.
                      </li>
                      <li>
                        To the extent permitted under law, in the event of
                        suspension or closure of any Services, events or
                        Contests, Users (including Participants) shall not be
                        entitled to make any demands, claims, on any nature
                        whatsoever.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>IBC Big Bonanza Contest</h3>
                  <div>
                    <ul>
                      <li>
                        Users participating in the IBC Big Bonanza Contest
                        hereby agree to be contacted by ICC Business Corporation
                        FZ LLC (“IBC”) for promotion and marketing purposes.
                        User information will only be shared if the user has
                        joined the IBC Big Bonanza contest. Users personal
                        information shall only be used where General Data
                        Protection Rules allows Big Bonanza to do so. User may
                        at any time withdraw their consent and request deletion
                        of their personal information by writing to Big Bonanza
                        Helpdesk and once Big Bonanza has received the user
                        notification, Big Bonanza will endeavour to delete or
                        de-identify the user information and will no longer use
                        users personal information unless Big Bonanza has a
                        legitimate basis for doing so by law.
                      </li>
                      <li>
                        The Participant(s) owning the Team(s) with the highest
                        aggregate score in a particular match shall be declared
                        the Winners. In the event of more than one participating
                        team having the same team score, the Winner will be
                        decided on the basis of points by earned by the captain
                        of such competing team. If the participating team(s)
                        have the same captain, then the participating team with
                        the vice -captain having higher score shall be declared
                        a Winner. In the event of participating team(s) having
                        the same captain and vice-captain, the Winner will be
                        declared based on the Participant submitting their team
                        first.
                      </li>
                      <li>
                        All prizes are non-transferable and non-refundable.
                        Prizes cannot be exchanged / redeemed for cash or kind.
                        No cash claims can be made in lieu of prizes in kind.
                      </li>
                      <li>
                        ICC shall be solely responsible for distribution of
                        prizes for all the IBC Big Bonanza Contest winners. Big
                        Bonanza shall not be held responsible for any lapses on
                        part of ICC to give the prizes.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Miscellaneous</h3>
                  <div>
                    <ul>
                      <li>
                        Big Bonanza may be required under certain legislations,
                        to notify User(s) of certain events. User(s) hereby
                        acknowledge and consent that such notices will be
                        effective upon Big Bonanza posting them on the Big
                        Bonanza or delivering them to the User through the email
                        address provided by the User at the time of
                        registration. User(s) may update their email address by
                        logging into their account on the Big Bonanza. If they
                        do not provide Big Bonanza with accurate information,
                        Big Bonanza cannot be held liable for failure to notify
                        the User.
                      </li>
                      <li>
                        Big Bonanza shall not be liable for any delay or failure
                        to perform resulting from causes outside its reasonable
                        control, including but not limited to any failure to
                        perform due to unforeseen circumstances or cause beyond
                        Big Bonanza's control such as acts of God, war,
                        terrorism, riots, embargoes, acts of civil or military
                        authorities, fire, floods, accidents, network
                        infrastructure failures, strikes, or shortages of
                        transportation facilities, fuel, energy, labor or
                        materials or any cancellation of any
                        cricket/football/basketball/kabaddi/volleyball/hockey/baseball/handball/American
                        football match to which a Contest relates. In such
                        circumstances, Big Bonanza shall also be entitled to
                        cancel any related Contest(s) and to process an
                        appropriate refund for all Participants.
                      </li>
                      <li>
                        Big Bonanza's failure to exercise or enforce any right
                        or provision of these Terms and Conditions shall not
                        constitute a waiver of such right or provision.
                      </li>
                      <li>
                        Users agree that regardless of any statute or law to the
                        contrary, any claim or cause of action arising out of or
                        related to use of the Big Bonanza or these Terms must be
                        filed within thirty (30) days of such claim or cause of
                        action arising or be forever barred.
                      </li>
                      <li>
                        These Terms and Conditions, including all terms,
                        conditions, and policies that are incorporated herein by
                        reference, constitute the entire agreement between the
                        User(s) and XENFORD INTERNET PRIVATE LIMITED and
                        govern your use of the Big Bonanza, superseding any
                        prior agreements that any User may have with XENFORD INTERNET PRIVATE LIMITED.
                      </li>
                      <li>
                        If any part of these Terms and Conditions is determined
                        to be indefinite, invalid, or otherwise unenforceable,
                        the rest of these Terms and Conditions shall continue in
                        full force.
                      </li>
                      <li>
                        Big Bonanza reserves the right to moderate, restrict or
                        ban the use of the Big Bonanza, specifically to any
                        User, or generally, in accordance with Big Bonanza's
                        policy/policies from time to time, at its sole and
                        absolute discretion and without any notice.
                      </li>
                      <li>
                        Big Bonanza may, at its sole and absolute discretion,
                        permanently close or temporarily suspend any Big Bonanza
                        Services (including any Contest(s)).
                      </li>
                      <li>
                        Big Bonanza may from time to time conduct/organize,
                        promotions/offers on the platform. Any two or more
                        promotions cannot be clubbed together with any other
                        promotions that are running simultaneously on the Big
                        Bonanza platform. Also, promotions/offers cannot be
                        clubbed with Discount Bonus available with any user for
                        any bundled contest joins via specially for you section
                        on the home page.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Grievance Redressal Mechanism</h3>
                  <div>
                    <ul>
                      <li>
                        In case you have any complaints or grievance pertaining
                        to (i) any User Content that you believe violates these
                        Terms (other than an infringement of Intellectual
                        Property Rights) for the reporting mechanism for such
                        claims), (ii) your access to the Platform or (iii) any
                        User Content which you believe is, prima facie, in the
                        nature of any material which is obscene, defamatory
                        towards the complainant or any person on whose behalf
                        you are making the complaint, or is in the nature of
                        impersonation in an electronic form, including
                        artificially morphed images of such individual, please
                        share the same with us by writing to:{" "}
                        <a href="#">info@BigBonanza.com</a>
                      </li>
                      <li>
                        In your complaint or grievance, please include the
                        following information:
                        <ul>
                          <li>
                            Your name and contact details: name, address,
                            contact number and email address;
                          </li>
                          <li>
                            Your relation to the subject matter of the
                            complaint, i.e. complainant or person acting on
                            behalf of an affected person;
                          </li>
                          <li>
                            The name and age of the person aggrieved or affected
                            by the subject matter of the complaint, in case your
                            are acting on behalf of such person and a statement,
                            under penalty of perjury, that you are authorised to
                            act on behalf of such person and to provide such
                            person's personal information to us in relation to
                            the complaint/grievance;
                          </li>
                          <li>
                            Description of the complaint or grievance with clear
                            identification of the User Content in relation to
                            which such complaint or grievance is made;
                          </li>
                          <li>
                            A statement that you believe, in good faith, that
                            the User Content violates these Terms
                          </li>
                          <li>
                            A statement that the information provided in the
                            complaint or grievance is accurate.
                          </li>
                        </ul>
                      </li>
                      <li>
                        The Company respects the Intellectual Property Rights of
                        others. All names, logos, marks, labels, trademarks,
                        copyrights or intellectual and proprietary rights on the
                        Platform belonging to any person (including User),
                        entity or third party are recognized as proprietary to
                        the respective owners. You are requested to send us a
                        written notice/ intimation if you notice any act of
                        infringement on the Platform, which must include the
                        following information:
                        <ul>
                          <li>
                            A clear identification of the copyrighted work
                            allegedly infringed;
                          </li>
                          <li>
                            A clear identification of the allegedly infringing
                            material on the Platform;
                          </li>
                          <li>
                            Your contact details: name, address, e-mail address
                            and phone number;
                          </li>
                          <li>
                            A statement that you believe, in good faith, that
                            the use of the copyrighted material allegedly
                            infringed on the Platform is not authorized by your
                            agent or the law;
                          </li>
                          <li>
                            A statement that the information provided in the
                            notice is accurate, and under penalty of perjury,
                            that the signatory is authorized to act on behalf of
                            the owner of an exclusive copyright right that is
                            allegedly infringed;
                          </li>
                          <li>
                            Your signature or a signature of your authorized
                            agent.
                          </li>
                        </ul>
                      </li>
                      {/*The aforesaid notices can be sent to the Company by email at <a href="#">info@BigBonanza.com</a>
          <li>On receiving such complaint, grievance or notice, the Company reserves the right to investigate and/or take such action as the Company may deem appropriate. The Company may reach out to you to seek further clarification or assistance with the investigation, or verify the statements made in your complaint, grievance or notice, and you acknowledge that your timely assistance with the investigation would facilitate the redressal of the same.</li>
          <li>The name and title of the Grievance Redressal Officer is as follows:
            <div>Name: Hitardha</div>
            <div>Email: info@BigBonanza.com</div>
            <div>Address: XENFORD INTERNET PRIVATE LIMITED, ONE BKC, Tower A, 12th &amp; 14th Floor, Unit 1201 &amp; 1202 and 1401 &amp; 1402, Plot C-66, G Block, Bandra Kurla Complex, Bandra (East), Mumbai 400 051.</div>
            <div>The Grievance Officer identified above pursuant to the provisions of applicable laws including but not limited to the Information Technology Act, 2000 and the Consumer Protection Act, 2019, and the Rules enacted under those laws.</div>
            <div>The Company reserves the right to replace the Grievance Redressal Officer at its discretion through publication of the name and title of such replacement on the website, which replacement shall come into effect immediately upon publication.</div>
          </li>*/}
                    </ul>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsandConditions;
