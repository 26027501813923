import React, { useState } from "react";
import InnerBanner from "./InnerBanner";
import "./Styles/Tc.css";

function PrivacyPolicy() {
  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper">
          <h1>Privacy Policy</h1>
          <div className="TC-Text">
            <div className="TermsCondtnsBox">
              {/* <h5>Last Updated: 1 October, 2024</h5> */}
              <ol>
                <li>
                  <h3>Big Bonanza</h3>
                  <div>
                    <div>
                     XENFORD INTERNET PRIVATE LIMITED Technologies Private Limited operates the portal in India, which offers cricket, football, basketball, baseball, esports, volleyball, handball, hockey, kabaddi, and other sporting event fantasy games through the web-portal bigbonanza.com and partner website(s) and mobile application(s) (collectively referred to as the "Portal").XENFORD INTERNET PRIVATE LIMITED Technologies Private Limited referred to herein as "BIG BONANZA" or "we" or "us" "our".
                    </div>
					<p>
					Any person utilizing the Portal ("User" or "you" or "your") or any of its features including participation in the various contests, games (including fantasy games ("Game") ("Services") being conducted on the Portal shall be bound by the terms of this privacy policy ("Privacy Policy").
					</p>
					<p>
					All capitalised terms not defined herein shall have the meaning ascribed to them in the Terms.
					</p>
					<p>
					BIG BONANZA respects the privacy of its Users and is committed to protect it in all respects. With a view to offer an enriching and holistic internet experience to its Users, BIG BONANZA offers a vast repository of Services. Kindly take time to read the 'About Us' section to know more about BIG BONANZA. Most of the Services are offered for free but you may need registration to participate in the Games. The information about the User is collected by BIG BONANZA as (i) information supplied by Users and (ii) information automatically tracked during User's navigation on BIG BONANZA.
					</p>
					<p>
					Before you submit any information to the Portal, please read this Privacy Policy for an explanation of how we will treat your information. By using any part of the Portal, you consent to the collection, use, disclosure and transfer of your information for the purposes outlined in this Privacy Policy and to the collection, processing and maintenance of this information. Your use of any part of the Portal indicates your acceptance of this Privacy Policy and of the collection, use and disclosure of your information in accordance with this Privacy Policy. While you have the option not to provide us with certain personal information, withdraw your consent to collect certain information, request temporary suspension of collection of personal information or request deletion of personal information collected, kindly note that in such an event you may not be able to take full advantage of the entire scope of features and services offered to you and we reserve the right not to provide you with our services.
					</p>
					<p>
					In the ever-evolving medium of the internet, We may periodically review and change our Privacy Policy to incorporate such future changes as may be considered appropriate. We will notify you of the change. Any changes to our Privacy Policy will be posted on this page, so you are always aware of what information we collect, how we use it, how we store it and under what circumstances we disclose it.
					</p>
                  </div>
                </li>
                <li>
                  <h3>Purpose and Usage</h3>
                  <div>
                    <ul>
                      <li>
                        To avail certain Services on the Portal, Users would be required to provide certain information for the registration process, namely:
                      </li>
                      <li>
                        Big Bonanza may, at its sole and absolute discretion:
                        <ul>
                          <li>Username</li>
                          <li>Email address </li>
                          <li>Date of birth</li>
                          <li>State</li>
                          <li>Government ID such as aadhaar card or driving license or voter id</li>
                          
                        </ul>
                      </li>
                      <li>
                        Additionally in the course of providing you with access to the Services, and in order to provide you access to the features offered through the Portal and to verify your identity, secure your account details, you may give us the permission to capture, record your device related information, operating system information, network information, location information etc. You may choose to allow us to gather information in relation to the apps installed on your device in order for us to better our Services and your experience on the Portal. You may also be required to furnish additional information, including your Permanent Account Number.
                      </li>
                      <li>
                        In certain instances, we may also collect sensitive personal information from you on the Portal. SPI means such personal information which consists of information relating to your financial information, such as information regarding the payment instrument/modes used by you to make such payments, which may include cardholder name, credit/debit card number (in encrypted form) with expiration date, banking details, wallet details etc ("SPI") This information is presented to you at the time of making a payment to enable you to complete your payment expeditiously.
                      </li>
                      
                      <li>
                        Except for any financial information that you choose to provide while making payment for any Services on the Portal, BIG BONANZA does not collect any other SPI in the course of providing the Services. Any SPI collected by BIG BONANZA shall not be disclosed to any third-party service provider without your express consent, save as otherwise set out in this Privacy Policy or as provided in a separate written agreement between BIG BONANZA and you or as required by law. It is clarified that this condition shall not apply to publicly available information, including SPI, in relation to you on the Portal.
					  </li>
                      <li>
                        We collect and use personal information provided by you (that is, information that may be used to identify you and that is not otherwise publicly available) to operate, provide, develop, and improve the Services, keeping you informed of Our offerings, and the products and/or services of our affiliates and group entities, and to enable Our affiliates and group entities to reach out to you in relation to the products and services offered by them.
					  </li>
                      <li>
                        In the course of providing the Services, Users may invite other existing Users or other users ("Invited Users") to participate in any of the Services through any social media platform including without limitation, Facebook, WhatsApp, Instagram etc. We may thereafter use this information to contact the Invited User and invite such user to register with BIG BONANZA (if such Invited User is not an existing User) and participate in the Game in relation to which such person was invited by you. The participation of the Invited User in any Game shall be subject to the terms of this Privacy Policy and the Terms and Conditions for the use of the Portal. You hereby represent that the Invited Users have consented and agreed to such disclosure of their information with us, our affiliates and/ or our group entities. You may also choose to invite your friends by syncing your phonebook and inviting them directly from the Portal to play the Game and utilize the Services.
					  </li>
                      <li>
						All required information is specific and based on the kind of Game/ Services the User wishes to participate in or access, and will be utilized to provide services, including but not limited to the Services requested by the User.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Disclosure/Sharing</h3>
                  <div>
                    <ul>
                      <li>
                        BIG BONANZA may share information as provided by you and data concerning your usage of the Services and participation in the Games with its affiliates and group entities to enable its affiliates and group entities to reach out to you in relation to the products and/ or services offered by them. BIG BONANZA may also share such information with third party service providers engaged by BIG BONANZA, for the purpose of data analytics or for the purpose of storage, improving the services (including product enhancements) and to help BIG BONANZA serve you better. Provided that affiliate, group entity and third-party service provider (as may be applicable) with whom the information is shared will be required, on best efforts basis, to comply with the same standards as applicable to BIG BONANZA in relation to maintaining the security of the information.
                      </li>
                      <li>
                        By using the Portal, you hereby expressly agree and grant consent to the collection, use and storage of your information by BIG BONANZA. BIG BONANZA reserves the right to share, disclose and transfer information collected hereunder with its affiliates and group entities. In the event BIG BONANZA sells or transfers all or a portion of its business assets, consumer information may be one of the business assets that are shared, disclosed or transferred as part of the transaction. You hereby expressly grant consent and permission to BIG BONANZA for disclosure and transfer of personal information to such third parties.
                      </li>
                      <li>
                        BIG BONANZA may share information and data concerning usage of the Services and participation in the Game with its commercial partners for the purpose of facilitating user engagement, for marketing and promotional purposes and other related purposes. Further, BIG BONANZA reserves the right to disclose/ share information with affiliates, group entities and third parties in limited circumstances, including for the purposes of complying with applicable law, responding to duly authorized legal process, governmental requests, preventing fraud or imminent harm, and ensuring the security of our network and services.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Use of Cookies</h3>
                  <div>
                    <ul>
                      <li>
                        To improve the effectiveness and usability of the Portal for our Users, we use "cookies", or such similar electronic tools to collect information to assign each visitor a unique random number as a User Identification (User ID) to understand the User's individual interests using the identified device. Unless the User voluntarily identifies himself/herself (e.g., through registration), BIG BONANZA has no way of knowing who the User is, even if we assign a cookie to the User's device. The only information a cookie can contain is information supplied by the User. A cookie cannot read data off the User's device hard drive. BIG BONANZA’s advertisers may also assign their own cookies to the User's device (if the User clicks on their ad banners), a process that BIG BONANZA does not control.
                      </li>
                      <li>
                        BIG BONANZA's web servers automatically collect limited information about User's device connection to the Internet, including User's IP address, when the User uses the Portal. (User's IP address is a number that lets devices attached to the Internet know where to send data to the User -- such as the web pages viewed by the User). The User's IP address does not identify the User personally. BIG BONANZA uses this information to deliver its web pages to Users upon request, to tailor its Portal to the interests of its users, to measure traffic within the Portal and let advertisers know the geographic locations from where BIG BONANZA's visitors come.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Links</h3>
                  <div>
                    <div>
                      BIG BONANZA also includes links to other websites. Such websites are governed by their respective privacy policies, which are beyond BIG BONANZA's control. Once the User leaves BIG BONANZA's servers (the User can tell where he/she is by checking the URL in the location bar on the User's browser), use of any information provided by the User is governed by the privacy policy of the operator of the site which the User is visiting. That policy may differ from BIG BONANZA's own. If the User can't find the privacy policy of any of these sites via a link from the site's homepage, the User may contact the site directly for more information. BIG BONANZA is not responsible for the privacy practices or the content of such websites.
                    </div>
                    <div></div>
                  </div>
                </li>
                <li>
                  <h3>Security Procedures</h3>
                  <div>
                    <ul>
                      <li>
                        All information gathered on BIG BONANZA is securely stored within the BIG BONANZA-controlled database. The database is stored on servers secured behind a firewall; access to such servers being password-protected and strictly limited based on need-to-know basis. However, we understand that as effective as our security measures are, no security system is impenetrable. Thus, we cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. Further, any information you include in a posting to the discussion areas will be available to anyone with internet access. By using the Portal, you understand and agree that your information may be used in or transferred to countries other than India.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Advertising:</h3>
                  <div>
                    <div>
                      When BIG BONANZA presents information to its online advertisers - to help them understand its audience and confirm the value of advertising on the Portal - it is usually in the form of aggregated anonymized data (information that cannot be used to trace a user) of Users that may include statistics and/or data concerning User traffic, User responsiveness and User behaviour to such advertisements on our Portal. When you register with BIG BONANZA, we contact you from time to time about updating your content to provide features which we believe may benefit you.
                    </div>
                    
                  </div>
                </li>
                <li>
                  <h3>Disclaimer</h3>
                  <div>
                    <ul>
                      <li>
                        Several deceptive emails, websites, blogs etc. claiming to be from or associated with BIG BONANZA may or are circulating on the Internet ("Deceptive Communication"). Such Deceptive Communication may include our logo, photos, links, content or other information. Some Deceptive Communication may call the User requesting the User to provide login name, password etc. or inform the User that the User has won a prize/ gift or provide a method to commit an illegal/ unauthorized act or deed or request detailed personal information or a payment of some kind. The sources and contents of these Deceptive Communications and accompanying materials are in no way associated with BIG BONANZA. For your own protection, we strongly recommend not responding to such Deceptive Communication. You agree that we shall not be liable for any loss, damage and harm that you may suffer by relying and/or acting upon such Deceptive Communications.
                      </li>
                      <li>
                        Except, as provided for the purpose of verification, as set out under the Terms and Conditions, we and/or our employees will not contact you to divulge your SPI.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Conditions of Use</h3>
                  <div>
                    <ul>
                      <li>
                        BIG BONANZA DOES NOT WARRANT THAT THIS PORTAL, IT’S SERVERS, OR EMAIL SENT BY US OR ON OUR BEHALF ARE VIRUS FREE. BIG BONANZA WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS PORTAL, INCLUDING, BUT NOT LIMITED TO COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL AND CONSEQUENTIAL DAMAGES, LOSS OF DATA, GOODWILL, BUSINESS OPPORTUNITY, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES. IN NO EVENT WILL BIG BONANZA BE LIABLE FOR ANY DAMAGES WHATSOEVER IN AN AMOUNT IN EXCESS OF AN AMOUNT OF INR 100.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3>Retention of Data</h3>
                  <div>
                    <ul>
                      <li>
                        Your personal information may be retained and may continue to be used until: (i) the relevant purposes for the use of your personal information described in this Privacy Policy are no longer applicable; and (ii) we are no longer required by applicable law, regulations, contractual obligations or legitimate business purposes to retain your personal information and the retention of your personal information is not required for the establishment, exercise or defense of any legal claim.
                      </li>
					</ul>
                  </div>
                </li>
                <li>
                  <h3>User Account and Data Deletion</h3>
                  <div>
                    <div>
                       Users are entitled to request BIG BONANZA to delete their User accounts and their personal information by sending an email with their written request to <a href="#">helpdesk@bigbonanza.com</a>.
                    </div>
                    <div>
					  We will do our best to respond promptly and in any event within one month of the following:
                    </div>
                    <ul>
                      <li>
                        <ul>
                          <li>
                            Our receipt of your written request; or
                          </li>
                          <li>
                            Our receipt of any further information we may ask you to provide, to enable us to comply with your request, whichever is later.
                          </li>
                        </ul>
                      </li>
                      <li>
						As an alternative to account deletion, by writing to <a href="#">helpdesk@bigbonanza.com</a>, you also have the option to request either:
                        <ul>
                          <li>
                            (i) the suspension of your account, after which you will not be able to play paid contests on your User account but will continue to have access to other parts of the Portal; or
                          </li>
                          <li>
                            (ii) temporary deactivation of your account, where you will no longer be able to log into the Portal but which allows you to request reactivation of your account with all your account data.
							If you proceed with permanent deactivation of your account, you will lose access to the Portal and the Services, including any User data and personal information associated with your account. Users may request for account restoration within six (6) months from the date of notification of account deletion by BIG BONANZA by writing to <a href="#">helpdesk@bigbonanza.com</a>.
                          </li>
                        </ul>
						
                      </li>
					</ul>
					<div>
                      When you request deletion of your data, we follow a deletion process that ensures that your data is safely and completely removed from our servers or retained only in anonymised form. We try to ensure that our services protect information from accidental or malicious deletion. Because of this, there may be delays between when you request deletion and when copies are deleted from our active and backup systems.
					</div>
                  </div>
                </li>
                <li>
                  <h3>Applicable Law and Jurisdiction</h3>
                  <div>
                    <div>
                      By visiting this Portal, you agree that the laws of the Republic of India without regard to its conflict of laws principles, govern this Privacy Policy and any dispute arising in respect hereof shall be subject to and governed by the dispute resolution process set out in the Terms and Conditions.
                    </div>
                  </div>
                </li>
                <li>
                  <h3>Updating Information</h3>
                  <div>
                    <div>
					  You will promptly notify BIG BONANZA if there are any changes, updates or modifications to your personal information. Further, you may also review, update or modify your personal information and User preferences by logging into your profile page on the Portal.
                    </div>
                  </div>
                </li>
                <li>
                  <h3>Contact Us</h3>
                  <div>
                    <ul>
                      <li>
                        In case you have any complaints or grievance pertaining
                        to (i) any User Content that you believe violates these
                        Terms (other than an infringement of Intellectual
                        Property Rights) for the reporting mechanism for such
                        claims), (ii) your access to the Platform or (iii) any
                        User Content which you believe is, prima facie, in the
                        nature of any material which is obscene, defamatory
                        towards the complainant or any person on whose behalf
                        you are making the complaint, or is in the nature of
                        impersonation in an electronic form, including
                        artificially morphed images of such individual, please
                        share the same with us by writing to:{" "}
                        <a href="#">info@BigBonanza.com</a>
                      </li>
                      <li>
                        In your complaint or grievance, please include the
                        following information:
                        <ul>
                          <li>
                            Your name and contact details: name, address,
                            contact number and email address;
                          </li>
                          <li>
                            Your relation to the subject matter of the
                            complaint, i.e. complainant or person acting on
                            behalf of an affected person;
                          </li>
                          <li>
                            The name and age of the person aggrieved or affected
                            by the subject matter of the complaint, in case your
                            are acting on behalf of such person and a statement,
                            under penalty of perjury, that you are authorised to
                            act on behalf of such person and to provide such
                            person's personal information to us in relation to
                            the complaint/grievance;
                          </li>
                          <li>
                            Description of the complaint or grievance with clear
                            identification of the User Content in relation to
                            which such complaint or grievance is made;
                          </li>
                          <li>
                            A statement that you believe, in good faith, that
                            the User Content violates these Terms
                          </li>
                          <li>
                            A statement that the information provided in the
                            complaint or grievance is accurate.
                          </li>
                        </ul>
                      </li>
                      <li>
                        The Company respects the Intellectual Property Rights of
                        others. All names, logos, marks, labels, trademarks,
                        copyrights or intellectual and proprietary rights on the
                        Platform belonging to any person (including User),
                        entity or third party are recognized as proprietary to
                        the respective owners. You are requested to send us a
                        written notice/ intimation if you notice any act of
                        infringement on the Platform, which must include the
                        following information:
                        <ul>
                          <li>
                            A clear identification of the copyrighted work
                            allegedly infringed;
                          </li>
                          <li>
                            A clear identification of the allegedly infringing
                            material on the Platform;
                          </li>
                          <li>
                            Your contact details: name, address, e-mail address
                            and phone number;
                          </li>
                          <li>
                            A statement that you believe, in good faith, that
                            the use of the copyrighted material allegedly
                            infringed on the Platform is not authorized by your
                            agent or the law;
                          </li>
                          <li>
                            A statement that the information provided in the
                            notice is accurate, and under penalty of perjury,
                            that the signatory is authorized to act on behalf of
                            the owner of an exclusive copyright right that is
                            allegedly infringed;
                          </li>
                          <li>
                            Your signature or a signature of your authorized
                            agent.
                          </li>
                        </ul>
                      </li>
                      {/*The aforesaid notices can be sent to the Company by email at <a href="#">info@BigBonanza.com</a>
          <li>On receiving such complaint, grievance or notice, the Company reserves the right to investigate and/or take such action as the Company may deem appropriate. The Company may reach out to you to seek further clarification or assistance with the investigation, or verify the statements made in your complaint, grievance or notice, and you acknowledge that your timely assistance with the investigation would facilitate the redressal of the same.</li>
          <li>The name and title of the Grievance Redressal Officer is as follows:
            <div>Name: Hitardha</div>
            <div>Email: info@BigBonanza.com</div>
            <div>Address:XENFORD INTERNET PRIVATE LIMITED Technologies Private Limited, ONE BKC, Tower A, 12th &amp; 14th Floor, Unit 1201 &amp; 1202 and 1401 &amp; 1402, Plot C-66, G Block, Bandra Kurla Complex, Bandra (East), Mumbai 400 051.</div>
            <div>The Grievance Officer identified above pursuant to the provisions of applicable laws including but not limited to the Information Technology Act, 2000 and the Consumer Protection Act, 2019, and the Rules enacted under those laws.</div>
            <div>The Company reserves the right to replace the Grievance Redressal Officer at its discretion through publication of the name and title of such replacement on the website, which replacement shall come into effect immediately upon publication.</div>
          </li>*/}
                    </ul>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
