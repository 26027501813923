// import React, { useState } from "react";
import React, { useState, useEffect } from "react";
import InnerBanner from "./InnerBanner";
import axios from "axios";
import congratulations from "./images/congratulations.png";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";

function Congratulations() {
  const [contestData, setContestData] = useState([]);
  const { width, height } = useWindowSize();

  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  return (
    <div>
      <InnerBanner />
      <Confetti width={width} height={height} />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper">
          {/* <h1>Congratulations </h1> */}
          <div className="congratulations-sec">
            <img src={congratulations} alt="congratulations" />
            <div className="clear"></div>
            <Link to="/Dashboard" className="btn-2">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Congratulations;
