import React from "react";
import ReactDOM from "react-dom";
//import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import TitleUpdater from './TitleUpdater';
ReactDOM.render(
  // <React.StrictMode>
  //   {/* <Router> */}
  //     <App />
  //   {/* </Router> */}
  // </React.StrictMode>,
  <React.StrictMode>
  <TitleUpdater />
  <App />
</React.StrictMode>,
  document.getElementById("root")
);
