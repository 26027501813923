import React from "react";
import './Styles/InnerBanner.css'

function InnerBanner() {
  return (
    <div>
      <div className="inner-banner-bg">
             
            </div>
    </div>
  );
}

export default InnerBanner;
