import React, { useState, useEffect } from "react";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useUserAuth } from "../context/UserAuthContext";
import "./Styles/PhoneSignUp.css";
import Banner from "./Banner";
import axios from "axios";
import { baseURL } from "../services/Aut.post.js";
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

const PhoneSignUp = ({ handleLogin , handleSignupStatusUpdate }) => {
  
    //use for sso
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const next = searchParams.get('next');
    const redirectto = searchParams.get('redirectto');
    const stocken = searchParams.get('stocken');
    const headers = {
      "Content-Type": "application/json",
      
    };
    const formData = {
      // Your form data goes here
      siteName: "Tockens",
      siteTocken: stocken
    };


  // Store the ref and next in localStorage when the component mounts
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    
    const ref = queryParams.get("ref");
    const next = queryParams.get("next");

    // alert(ref , next)
    
    if (ref) {
      localStorage.setItem("referral_code", ref);
    }
    if (next) {
      localStorage.setItem("next_url", next);
    }
  }, [location]);




  // Get Next url to split
 


  

const [authTokens, setAuthTokens] = useState(() => 
  localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null
);
const [user, setUser] = useState(() => 
  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
);


// const setToken = (token) => {
//   Cookies.set('authToken', token, { domain: 'app1.localhost', path: '/' });
//   Cookies.set('authToken', token, { domain: 'app2.localhost', path: '/' });

// };

// const getToken = () => {
//   return Cookies.get('authToken');
// };

// const removeToken = () => {
//   Cookies.remove('authToken', { domain: 'app2.localhost' });
// };


  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  const { setUpRecaptha } = useUserAuth();
  
  const [loadingSendOTP, setLoadingSendOTP] = useState(false);
  const [loadingVerifyOTP, setLoadingVerifyOTP] = useState(false);

  const getOtp = async (e) => {
    e.preventDefault();
    console.log(number);

  

      


    setError("");
    setLoadingSendOTP(true);
    console.log("Number Lenth", number.toString().length);
    if (
      number === "" ||
      number === undefined ||
      number.toString().length < 13 ||
      number.toString().length > 15
    )
      //This is only for india
      {
        setLoadingSendOTP(false); // Reset loading state
        return setError("Please enter a valid phone number");
      }
    try {
      const response = await setUpRecaptha(number);
      setResult(response);
      setFlag(true);
    } catch (err) {
  
      setLoadingSendOTP(false);
      //console.log("API Key:", process.env.REACT_APP_FIREBASE_API_KEY);
      //console.log("Auth Domain:", process.env.REACT_APP_FIREBASE_AUTH_DOMAIN);
      console.log(err.message)
      // setError(err.message);
      if (err.code === "auth/invalid-phone-number") {
        setError("Invalid phone number. Please enter a valid phone number.");
      } else if (err.code === "auth/too-many-requests") {
        setError("Too many requests. Please try again later.");
      } else {
        alert(err.code)
        setError("An error occurred. Please try again.");
      }
    }
  };

// Function to extract referral code from URL


 

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
  
    if (otp === "" || otp === null) return;
  
    try {
      await result.confirm(otp);

      // const { search } = useLocation();
      // const urlParams = new URLSearchParams(search);
      // const referralCodeFromUrl = urlParams.get('ref') || null;

      // // Set referral code in localStorage
      // if (referralCodeFromUrl) {
      //     localStorage.setItem("refCode", referralCodeFromUrl);
      // }
  
      // Set auth user token
      axios.post(`${baseURL}/userapi/settoken/`, {
        phone_number: number,
      })
      .then((response) => {
        if (response.data) {
          
          // Store userLoginFirst in localStorage
          localStorage.setItem("userLoginFirst", JSON.stringify(true));

          // const refcode = "FREESUNDAY";
          // localStorage.setItem("refCode", refcode);
          
          // Store other user data in localStorage
          localStorage.setItem("userLoginStatus", JSON.stringify(response.data));
          localStorage.setItem("userphonenumber", JSON.stringify(number));
  
          const { signup_status, user_referal_code } = response.data;
          // alert(response.data.signup_status);
          const storedLoginStatus = JSON.parse(localStorage.getItem("userLoginStatus")) || {};
          const storedSignupStatus = storedLoginStatus.signup_status;

          handleSignupStatusUpdate(signup_status, storedSignupStatus);
          
          // alert(response.data.signup_status);
           let getrefCode = user_referal_code || localStorage.getItem("referral_code");
           const getnextUrl = localStorage.getItem("next_url")
          

          if (response.data.signup_status === 0 || storedSignupStatus === 0 ) {
            // Navigate to the signup page if signup_status is 0
            if (getrefCode) {
              navigate(`/SignUp?ref=${getrefCode}&next=${getnextUrl}`);
            } else {
              navigate(`/SignUp`);
            }
            return; // Exit the function after navigation
          } else if (signup_status === true) {
            setAuthTokens(response.data);
            setUser(response.data.access);
            localStorage.setItem('authTokens', JSON.stringify(response.data));
            localStorage.setItem("user", JSON.stringify(response.data));
  
            const token = JSON.stringify(response.data);
            handleLogin(token);
  
            if (next) {
              const nexturl = new URL(next);
              const nextbaseUrl = nexturl.origin;
  
              const sendRequest = async () => {
                try {
                  const returnResponse = await axios.post(
                    `${baseURL}/userapi/checksitegettocken/`,
                    formData,
                    { headers: headers }
                  );
                  if (returnResponse.data.site_verfy === true) {
                    const url = new URL(next);
                    url.searchParams.set('token', response.data.access);
                    url.searchParams.set('retoken', response.data.refresh);
                    url.searchParams.set('redirectto', redirectto);
  
                    // Redirect to the modified URL
                    window.location.href = url.toString();
                  } else {
                    // Handle error
                  }
                } catch (error) {
                  console.error("Error:", error.response?.data?.message || error.message);
                }
              };
  
              sendRequest();
            } else {
              window.location.href = "/Dashboard";
            }
          } else {
            console.error("Unexpected signup_status:", signup_status);
          }
        }
  
        return response.data;
      });
    } catch (err) {
      if (err.code === "auth/invalid-verification-code") {
        setError("Invalid OTP. Please enter a valid OTP.");
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };
  
//   const verifyOtp = async (e) => {
//     e.preventDefault();
//     setError("");

//     if (otp === "" || otp === null) return;

//     try {
//         await result.confirm(otp);

        // const { search } = useLocation();
        // const urlParams = new URLSearchParams(search);
        // const referralCodeFromUrl = urlParams.get('ref') || null;

        // // Set referral code in localStorage
        // if (referralCodeFromUrl) {
        //     localStorage.setItem("refCode", referralCodeFromUrl);
        // }

//         // Set auth user token
//         axios.post(`${baseURL}/userapi/settoken/`, {
//             phone_number: number,
//         })
//         .then((response) => {
//             if (response.data) {

//                 // Store userLoginFirst in localStorage
//                 localStorage.setItem("userLoginFirst", JSON.stringify(true));
                
//                 // Store other user data in localStorage
//                 localStorage.setItem("userLoginStatus", JSON.stringify(response.data));
//                 localStorage.setItem("userphonenumber", JSON.stringify(number));

//                 const { signup_status, user_referal_code } = response.data;

//                 // Set referral code in localStorage
//                 if (user_referal_code) {
//                     localStorage.setItem("refCode", user_referal_code);
//                 }

//                 // Check if referral code is available
//                 const refCode = localStorage.getItem("refCode");

//                 handleSignupStatusUpdate(signup_status, JSON.parse(localStorage.getItem("userLoginStatus"))?.signup_status);

//                 alert(response.data.signup_status);
                
//                 if (signup_status === 0) {
//                     // Navigate to the signup page if signup_status is 0
//                     if (refCode) {
//                         navigate(`/SignUp?ref=${refCode}&next=https://bigbonanza.in/?contest=12345t`);
//                     } else {
//                         navigate(`/SignUp?ref=null&next=https://bigbonanza.in/?contest=12345t`);
//                     }
//                     return; // Exit the function after navigation
//                 } else if (signup_status === true) {
//                     setAuthTokens(response.data);
//                     setUser(response.data.access);
//                     localStorage.setItem('authTokens', JSON.stringify(response.data));
//                     localStorage.setItem("user", JSON.stringify(response.data));

//                     const token = JSON.stringify(response.data);
//                     handleLogin(token);

//                     if (next) {
//                         const nexturl = new URL(next);
//                         const nextbaseUrl = nexturl.origin;

//                         const sendRequest = async () => {
//                             try {
//                                 const returnResponse = await axios.post(
//                                     `${baseURL}/userapi/checksitegettocken/`,
//                                     formData,
//                                     { headers: headers }
//                                 );
//                                 if (returnResponse.data.site_verfy === true) {
//                                     const url = new URL(next);
//                                     url.searchParams.set('token', response.data.access);
//                                     url.searchParams.set('retoken', response.data.refresh);
//                                     url.searchParams.set('redirectto', redirectto);

//                                     // Redirect to the modified URL
//                                     window.location.href = url.toString();
//                                 } else {
//                                     // Handle error
//                                 }
//                             } catch (error) {
//                                 console.error("Error:", error.response?.data?.message || error.message);
//                             }
//                         };

//                         sendRequest();
//                     } else {
//                         window.location.href = "/Dashboard";
//                     }
//                 } else {
//                     console.error("Unexpected signup_status:", signup_status);
//                 }
//             }

//             return response.data;
//         });
//     } catch (err) {
//         if (err.code === "auth/invalid-verification-code") {
//             setError("Invalid OTP. Please enter a valid OTP.");
//         } else {
//             setError("An error occurred. Please try again.");
//         }
//     }
// };



  return (
    <>
      <div className="Login-Bg">
        <div id="recaptcha-container"></div>
        <div className="Login-wrap">
          <Form onSubmit={getOtp} style={{ display: !flag ? "block" : "none" }}>
            <div className="Login-Header-Text">
              <h2>Login</h2>
              <h6>Enter you phone number to login into your account.</h6>
              <div className="closePopup1">
                <Link to="/">
                  <Button variant="secondary">X</Button>
                </Link>
              </div>
            </div>
            <div className="Login-Content-wrap">
              {error && <Alert variant="danger">{error}</Alert>}

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <p>Phone No.</p>
                <PhoneInput
                  //international
                  defaultCountry="IN"
                  value={number}
                  onChange={setNumber}
                  placeholder="Enter Phone Number"
                  maxLength="11"
                  countryCallingCodeEditable={false}
                  autoFocus
                />
              </Form.Group>
              <div className="button-right">
                {/* <Button type="submit" className="button-2 w-100">
                  Send OTP
                </Button> */}

<Button type="submit" className="button-2 w-100" disabled={loadingSendOTP || loadingVerifyOTP} > {loadingSendOTP ? "Please wait..." : "Send OTP"} </Button>


              </div>
            </div>
          </Form>

          <Form
            onSubmit={verifyOtp}
            style={{ display: flag ? "block" : "none" }}
          >
            <div className="Login-Header-Text">
              <h2>Phone Verification</h2>
              <h6>
                Please enter the OTP that was Sent to your phone number.
              </h6>
              <div className="closePopup1">
                <Link to="/">
                  <Button variant="secondary">X</Button>
                </Link>
              </div>
            </div>
            <div className="Login-Content-wrap">
              {error && <Alert variant="danger">{error}</Alert>}
              <Form.Group className="mb-3" controlId="formBasicOtp">
                <p>OTP</p>
                <Form.Control
                  type="otp"
                  placeholder="Enter OTP"
                  onChange={(e) => setOtp(e.target.value)}
                  autoFocus
                />
              </Form.Group>
              <div className="button-right">
                <Button type="submit" className="button-2 w-100">
                  Verify OTP
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Banner />
    </>
  );
};

export default PhoneSignUp;
