// import React from "react";
// import { Navigate } from "react-router-dom";
// import { useUserAuth } from "../context/UserAuthContext";
// const ProtectedRoute = ({ children }) => {
//   const { user } = useUserAuth();
//   if (!user) {
//     return <Navigate to="/" />;
//   }
//   return children;
// };

// export default ProtectedRoute;


// import React from 'react';
// import { Route, Navigate } from 'react-router-dom';

// const ProtectedRoute = ({ element: Component, isAuthenticated, signupStatus, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       element={isAuthenticated ? (
//         signupStatus === 0 ? (
//           <Component />
//         ) : (
//           <Navigate to="/Dashboard" />
//         )
//       ) : (
//         <Navigate to="/" />
//       )}
//     />
//   );
// };

// export default ProtectedRoute;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ isAuthenticated }) => {
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute